import { useSession } from "@api";
import {
	DISPLAY_DATE_FORMAT,
	Grid,
	GridLabel,
	PaperTitle,
	Stack,
	TIME_FORMAT,
	Typography,
	isoDurationToString,
	stringISOToDateTimeNullable,
	useContextModule,
} from "@key4-front-library/core";
import { t } from "i18next";
import { useParams } from "react-router-dom";

export const ScheduleSectionSession = (): React.ReactNode => {
	const component = "scheduleSectionSession";

	const { sessionId = "" } = useParams();
	const { client, event } = useContextModule();

	const sessionQuery = useSession(client.id, event.id, sessionId);

	if (!sessionQuery.data) {
		return undefined;
	}
	const startDate = stringISOToDateTimeNullable(sessionQuery.data.startDate);
	const endDate = stringISOToDateTimeNullable(sessionQuery.data.endDate);

	return (
		<Stack>
			<PaperTitle
				title={{
					icon: { variant: "h5", prefix: "fal", name: "calendar-days", color: "primary.light" },
					children: <Typography variant="h5">{t(`${component}.title`)}</Typography>,
				}}
			>
				<Grid container spacing={2}>
					<GridLabel label={t(`${component}.displayer.startDate`)}>
						<Typography>{startDate ? startDate.toFormat(DISPLAY_DATE_FORMAT) : <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.startHour`)}>
						<Typography>{startDate ? startDate.toFormat(TIME_FORMAT) : <br />}</Typography>
					</GridLabel>
					<Grid item xs={0} md={4} lg={6} />
					<GridLabel label={t(`${component}.displayer.endDate`)}>
						<Typography>{endDate ? endDate.toFormat(DISPLAY_DATE_FORMAT) : <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.endHour`)}>
						<Typography>{endDate ? endDate.toFormat(TIME_FORMAT) : <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.duration`)}>
						<Typography>{sessionQuery.data.duration ? isoDurationToString(sessionQuery.data.duration) : <br />}</Typography>
					</GridLabel>
				</Grid>
			</PaperTitle>
		</Stack>
	);
};
