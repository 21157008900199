import type { RoomRead, SessionStatusType, TagRead, TagTypeRead } from "@api";
import { getSessionStatus } from "@configurations";
import { type ActionItem, type AutocompleteProps, type BulletTextProps, type LabelWithCaptionProps, type TagProps, Typography } from "@key4-front-library/core";
import type { Theme } from "@mui/material";
import { t } from "i18next";
import type { UseFormReturn } from "react-hook-form";
import type { SessionModalForm } from "../SessionModal";

export const tagsReadToItems = (tags: Array<TagRead>): Array<ActionItem<TagProps>> =>
	tags.map((tag) => ({
		value: tag.id,
		name: tag.label,
		component: {
			backgroundColor: tag.backgroundColor,
			borderColor: tag.borderColor,
			fontColor: tag.fontColor,
			children: <Typography>{tag.label}</Typography>,
		},
	}));

export const roomsReadToAutocompleteItem = (rooms: Array<RoomRead>): Array<ActionItem<LabelWithCaptionProps>> =>
	rooms.map((room) => ({
		value: room.id,
		name: room.name,
		component: {
			label: room.name,
			caption: room.capacity ? t("room.capacity", { capacity: room.capacity }) : t("room.capacityNotDefined"),
		},
	}));

export const getSessionStatusesOptions = (theme: Theme): Array<ActionItem<BulletTextProps>> => {
	const sessionStatuses = getSessionStatus(theme);
	return Object.keys(sessionStatuses).map((sessionStatus) => {
		const status = sessionStatuses[sessionStatus as SessionStatusType];
		return {
			value: sessionStatus,
			name: status.name,
			component: {
				bulletColor: status.color,
				text: status.name,
				typography: { variant: "body1" },
			},
		};
	});
};

export interface SecondariesTagsSelectReturn {
	key: string;
	autocomplete: Omit<AutocompleteProps, "options">;
	max?: number;
	options: Array<ActionItem<TagProps>>;
}

export const tagsToSecondariesTagsSelect = (
	tagTypes: Array<TagTypeRead>,
	tags: Array<TagRead>,
	form: UseFormReturn<SessionModalForm, undefined, undefined>,
): Array<SecondariesTagsSelectReturn> => {
	return tagTypes.slice(1).map((tagType) => {
		if (!form.getValues(`tags.${tagType.id}`)) {
			form.setValue(`tags.${tagType.id}`, []);
		}
		const label = tagType.max ? `${tagType.label} (${tagType.max} max)` : tagType.label;

		return {
			key: tagType.id,
			max: tagType.max,
			autocomplete: { label },
			options: tags
				.filter((tag) => tag.tagTypeId === tagType.id)
				.map((tag) => ({
					value: tag.id,
					name: tag.label,
					component: {
						backgroundColor: tag.backgroundColor,
						borderColor: tag.borderColor,
						fontColor: tag.fontColor,
						children: tag.label,
					},
				})),
		};
	});
};
