import { usePresentationSpeaker, useSession, useSessionTemplates, useSpeakerCreate, useSpeakerUpdate } from "@api";
import {
	SpeakerForm,
	type SpeakerModalForm,
	overrideSpeakerWithSessionTemplate,
	speakerDefaultValues,
	speakerReadToSpeakerForm,
	speakerReadToUserInformationProps,
	speakerSchema,
	typeContactToParticipantWrite,
} from "@components";
import { speakerFormToSpeakerWrite } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateSpeakerFromContact } from "@hooks";
import {
	type CustomFieldForm,
	DialogAdvanced,
	FilteringOperator,
	FormTabsCustomField,
	type UserInformationProps,
	filtersToQueryString,
	getDialogAdvanced,
	useContextModule,
	useSnackbar,
} from "@key4-front-library/core";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { type FieldErrors, FormProvider, useForm } from "react-hook-form";

export interface SpeakerModalProps {
	sessionId: string;
	isOpened: boolean;
	onClose: () => void;
	onCallbackSubmit?: () => void;
	presentationId: string;
	speakerParticipantId?: string;
}

export const SpeakerModal = (props: SpeakerModalProps) => {
	const component = "speakerModal";
	const { sessionId, isOpened, onClose, presentationId, speakerParticipantId, onCallbackSubmit } = props;

	const [isLoaded, setIsLoaded] = useState(false);
	const [userInformation, setUserInformation] = useState<UserInformationProps>();

	const { client, event } = useContextModule();
	const snackbar = useSnackbar();
	const presentationSpeakerQuery = usePresentationSpeaker(client.id, event.id, sessionId, presentationId, speakerParticipantId ?? "", {
		enabled: !!speakerParticipantId,
		queryStrings: [{ key: "includeCustomFields", value: "true" }],
	});
	const sessionQuery = useSession(client.id, event.id, sessionId);
	const sessionTemplatesQuery = useSessionTemplates(client.id, event.id, {
		queryStrings: filtersToQueryString(`primaryTagId${FilteringOperator.Equal}${sessionQuery.data?.primaryTag?.id}`),
		enabled: !!sessionQuery.data?.primaryTag?.id && !speakerParticipantId,
	});

	const form = useForm<CustomFieldForm<SpeakerModalForm>>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: speakerDefaultValues,
		resolver: zodResolver(speakerSchema(speakerParticipantId)),
	});

	useEffect(() => {
		if (presentationSpeakerQuery.data) {
			setUserInformation(speakerReadToUserInformationProps(presentationSpeakerQuery.data));
		}
	}, [presentationSpeakerQuery.data]);

	useEffect(() => {
		if ((presentationSpeakerQuery.data || !speakerParticipantId) && isLoaded) {
			form.reset(speakerReadToSpeakerForm(form.getValues("customFields"), presentationSpeakerQuery.data));
		}
		if (!speakerParticipantId) {
			overrideSpeakerWithSessionTemplate(form, sessionTemplatesQuery.data?.data?.[0]);
		}
	}, [form, sessionTemplatesQuery.data?.data?.[0], presentationSpeakerQuery.data, form.getValues, isLoaded, speakerParticipantId]);

	const handleSpeakerSubmit = (_: string | boolean): void => {
		snackbar.enqueue({ type: "success", translation: speakerParticipantId ? `${component}.edit` : `${component}.create` });
		onCallbackSubmit?.();
		onClose();
	};

	const createSpeakerFromContact = useCreateSpeakerFromContact({ onSuccess: handleSpeakerSubmit });
	const createSpeaker = useSpeakerCreate({ onSuccess: handleSpeakerSubmit });
	const speakerEdit = useSpeakerUpdate({ onSuccess: handleSpeakerSubmit });

	const handleSubmit = useCallback(
		(data: CustomFieldForm<SpeakerModalForm>) => {
			if (speakerParticipantId && presentationSpeakerQuery.data) {
				speakerEdit.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					presentationId,
					participantId: presentationSpeakerQuery.data.participantId,
					body: speakerFormToSpeakerWrite(data),
				});
				return;
			}
			if (data.participantOrContact?.category === "contacts") {
				createSpeakerFromContact.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					presentationId,
					body: speakerFormToSpeakerWrite(data),
					bodyParticipant: typeContactToParticipantWrite(data.participantOrContact),
				});
				return;
			}

			if (data.participantOrContact?.category === "participants") {
				createSpeaker.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId,
					presentationId,
					participantId: data.participantOrContact.id,
					body: speakerFormToSpeakerWrite(data),
				});
				return;
			}
			snackbar.enqueue({ type: "unexpected" });
		},
		[
			client.id,
			event.id,
			speakerParticipantId,
			speakerEdit,
			sessionId,
			createSpeaker,
			createSpeakerFromContact,
			presentationId,
			snackbar.enqueue,
			presentationSpeakerQuery.data,
		],
	);

	const handleError = useCallback(
		(_errors: FieldErrors<CustomFieldForm<SpeakerModalForm>>) => {
			snackbar.enqueue({ type: "invalidForm", translation: t("form.validation") });
		},
		[snackbar.enqueue],
	);

	const handleIsLoaded = () => setIsLoaded(true);

	const handleSaveClick = (): void => {
		void form.handleSubmit(handleSubmit, handleError)();
	};

	if (userInformation === undefined && speakerParticipantId) {
		return undefined;
	}

	return (
		<DialogAdvanced
			open={isOpened}
			{...getDialogAdvanced({
				type: "save",
				translation: speakerParticipantId ? `${component}.edit` : `${component}.create`,
				options: {
					onCancelClick: onClose,
					onActionClick: handleSaveClick,
				},
			})}
			fullWidth
		>
			<FormProvider {...form}>
				<FormTabsCustomField
					id={speakerParticipantId}
					onIsLoaded={handleIsLoaded}
					module="programme"
					scope="speaker"
					businessForm={<SpeakerForm isEditMode={!!speakerParticipantId} userInformation={userInformation} />}
				/>
			</FormProvider>
		</DialogAdvanced>
	);
};
