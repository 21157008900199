import { t } from "i18next";
import { User } from "oidc-client-ts";
import { useEffect, useState } from "react";
import MailTemplatesController from "@application/Controllers/MailTemplateController";
import type { TypeUseFormSendMailTemplateValue } from "@application/Types/MailTemplateType";
import {
	AuthenticationConfig,
	type DtoMailTemplateGet,
	EnumMailTemplateType,
	EnumTarget,
	queryStringSorts,
	useContextModule,
	useMailTemplateSendToFaculty,
	useSnackBarHook,
} from "@key4-front-library/core";
import type { TSelectItemWithIcon } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelectIcon";

import ModalSendMail from "../components/MailTemplates/ModalSendMail";

type ContainerMailTemplatesModalProps = {
	isOpenModal: boolean;
	handleModalClose: (reason?: string) => void;
	facultyId: string;
};

export const ContainerSendMailModal = (props: ContainerMailTemplatesModalProps) => {
	const { isOpenModal, handleModalClose, facultyId } = props;
	const { sendSuccess, sendError } = useSnackBarHook();
	const { client, event } = useContextModule();

	const [mailTemplates, setMailTemplates] = useState<Array<TSelectItemWithIcon>>([]);

	const storageKey = `oidc.user:${AuthenticationConfig.authority}:${AuthenticationConfig.client_id}`;
	const oidcStorageData = sessionStorage.getItem(storageKey);
	const user = User.fromStorageString(oidcStorageData || "");

	const sendMailToFaculty = useMailTemplateSendToFaculty({});

	const getMailTemplatesList = async () => {
		const sortsParams: Array<string> = [];

		// we always want to display the templates that are not "custom" first
		sortsParams.push("-type");

		const sortQueryParam = queryStringSorts(sortsParams);

		const templates = (await MailTemplatesController.getMailTemplatesList(client.id, event.id, [...sortQueryParam])).filter(
			(template: DtoMailTemplateGet) => template.target == EnumTarget.Faculty,
		);

		const templatesList: Array<TSelectItemWithIcon> = [];

		templates.forEach((template: DtoMailTemplateGet) => {
			templatesList.push({
				key: template.id,
				label: template.name || "",
				icon: {
					name: template.type === EnumMailTemplateType.CUSTOM ? "palette" : "bell",
					prefix: "far",
				},
			});
		});

		setMailTemplates(templatesList);
	};

	const handleSendPreview = (form: TypeUseFormSendMailTemplateValue) => {
		sendMailToFaculty
			.mutateAsync({
				clientId: client.id,
				eventId: event.id,
				facultyId,
				mailTemplateId: form.template,
				emailPreview: user.profile.email ?? null,
			})
			.then(() => {
				sendSuccess(t("old.programme.facultyDetails.sendMailModal.sendPreview.success"));
			})
			.catch(() => {
				sendError(t("old.programme.facultyDetails.sendMailModal.sendPreview.error"));
			})
			.finally(() => {
				handleModalClose();
			});
	};

	const handleSend = (form: TypeUseFormSendMailTemplateValue) => {
		sendMailToFaculty
			.mutateAsync({
				clientId: client.id,
				eventId: event.id,
				facultyId,
				mailTemplateId: form.template,
				emailPreview: null,
			})
			.then(() => {
				sendSuccess(t("old.programme.facultyDetails.sendMailModal.send.success"));
			})
			.catch(() => {
				sendError(t("old.programme.facultyDetails.sendMailModal.send.error"));
			})
			.finally(() => {
				handleModalClose();
			});
	};

	useEffect(() => {
		getMailTemplatesList();
	}, []);

	return (
		<ModalSendMail
			isOpenModal={isOpenModal}
			handleModalClose={handleModalClose}
			handleSendPreview={handleSendPreview}
			handleSend={handleSend}
			templates={mailTemplates}
		></ModalSendMail>
	);
};
