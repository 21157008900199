import { toPairs, trim } from "lodash";

import { EnumApiPatchOperation, PATCH_PREFIX_CUSTOM_FIELD, type TJSONPatch } from "@infrastructure/model/interfaces/api.interface";
import DateService from "@infrastructure/services/dates/date.service";
import {
	type CustomFieldValue,
	type CustomFieldValueRead,
	ErrorAPI,
	ISO_DATE_FORMAT,
	KindFieldLists,
	type TFilter,
	type TUseFormBulkAction,
	type TUseFormPickField,
	type UseFormPickFields,
	customFieldBasicValueToCustomFieldValueRead,
	customFieldListValueToCustomFieldValueRead,
	sieveStringFiltersPageList,
} from "@key4-front-library/core";
import type { TAutocompleteMultiChipColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteMultiChipColor";
import { EField } from "@key4-front-library/core/Enums";
import type { GridRowsProp } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";

export enum EBulkActionAction {
	EDIT = "edit",
	TRANSITION = "transition",
	DELETE = "delete",
	MAILING = "mailing",
	PUBLISH = "publish",
	NUMBERING = "numbering",
}

type TActionBulkAction = {
	key: string;
	label: string;
	labelInfo?: string;
};

const actionsObject: { [key in EBulkActionAction]: TActionBulkAction } = {
	[EBulkActionAction.EDIT]: {
		key: EBulkActionAction.EDIT,
		label: "old.common.bulkAction.actions.edit.label",
		labelInfo: "old.common.bulkAction.actions.edit.labelInfo",
	},
	[EBulkActionAction.TRANSITION]: {
		key: EBulkActionAction.TRANSITION,
		label: "old.common.bulkAction.actions.transition.label",
		labelInfo: "old.common.bulkAction.actions.transition.labelInfo",
	},
	[EBulkActionAction.DELETE]: {
		key: EBulkActionAction.DELETE,
		label: "old.common.bulkAction.actions.delete.label",
		labelInfo: "old.common.bulkAction.actions.delete.labelInfo",
	},
	[EBulkActionAction.MAILING]: {
		key: EBulkActionAction.MAILING,
		label: "old.common.bulkAction.actions.mailing.label",
	},
	[EBulkActionAction.PUBLISH]: {
		key: EBulkActionAction.PUBLISH,
		label: "old.common.bulkAction.actions.publish.label",
		labelInfo: "old.common.bulkAction.actions.publish.labelInfo",
	},
	[EBulkActionAction.NUMBERING]: {
		key: EBulkActionAction.NUMBERING,
		label: "old.common.bulkAction.actions.numbering.label",
		labelInfo: "old.common.bulkAction.actions.numbering.labelInfo",
	},
};

type TFieldOptions = {
	[key: string]: any;
};

export type TBulkActionDefaultValueUseForm = {
	customField: TFieldOptions;
	staticField: TFieldOptions;
};

// TODO D1: remove function when tags has better implementation
type TTagsPatch = {
	operationsAdd: Array<TJSONPatch>;
	operationsRemove: Array<TJSONPatch>;
};
const getTagsPatch = (useFormSubmit: TUseFormBulkAction): TTagsPatch => {
	const operationsAdd: TJSONPatch = {
		op: EnumApiPatchOperation.ADD,
		path: "/tags",
		value: [],
	};
	const operationsRemove: TJSONPatch = {
		op: EnumApiPatchOperation.REMOVE,
		path: "/tags",
		value: [],
	};

	// if Primary tag remove all primaryTags and add primary tag selected
	toPairs(useFormSubmit.staticField).forEach((fieldSubmitted) => {
		const key: string = fieldSubmitted[0];
		const field: TUseFormPickField = fieldSubmitted[1];

		// (filter) Get only checked tags field
		if ((key !== "primaryTag" && !key.startsWith("mf__tags__")) || !field.isChecked) {
			return;
		}

		const selectedItem = typeof field.value === "string" ? [field.value] : [];

		let selectedItems: Array<string> = [];
		if (typeof field.value === "string") {
			selectedItems = [field.value];
		} else {
			selectedItems = field.value ? field.value.map((fieldValue: string) => fieldValue) : [];
		}

		const allItems = field.ids ? field.ids : [];
		if (!field.isMultiSelect) {
			operationsAdd.value = operationsAdd.value.concat(selectedItem);
			operationsRemove.value = operationsRemove.value.concat(allItems);
		} else {
			if (!field.operation) {
				return;
			}
			switch (field.operation) {
				case EnumApiPatchOperation.ADD:
					operationsAdd.value = operationsAdd.value.concat(selectedItems);
					break;
				case EnumApiPatchOperation.CLEAR:
					operationsRemove.value = operationsRemove.value.concat(allItems);
					break;
				case EnumApiPatchOperation.REMOVE:
					operationsRemove.value = operationsRemove.value.concat(selectedItems);
					break;
				case EnumApiPatchOperation.REPLACE:
				default:
					operationsRemove.value = operationsRemove.value.concat(allItems);
					operationsAdd.value = operationsAdd.value.concat(selectedItems);
					break;
			}
		}
	});

	return {
		operationsAdd: [operationsAdd],
		operationsRemove: [operationsRemove],
	};
};

const mapBulkActionBusinessFieldsToPatch = (field: UseFormPickFields): Array<TJSONPatch> => {
	return (
		toPairs(field)
			.map((val) => {
				return {
					key: val[0],
					...val[1],
					isCustomField: false,
				};
			})
			// Remove not checked field & tags
			.filter((field) => field.key !== "primaryTag" && !field.key.startsWith("mf__tags__") && field.isChecked)
			// Remap as TJSONPatch instance
			.map((field) => {
				let value: any = null;
				switch (field.kind as EField) {
					case EField.AUTO_COMPLETE_SUB_LABEL:
						value = field.value;
						break;
					case EField.AUTO_COMPLETE_BULLET_COLOR:
						value = field.value.key;
						break;
					case EField.AUTOCOMPLETE_MULTI_CHIP_COLOR:
						value = field.value.map((val: TAutocompleteMultiChipColorItem) => {
							return val.key;
						});
						break;
					case EField.AUTO_COMPLETE:
						value = [field.value.key];
						break;
					case EField.DATE_PICKER:
						value = field.value;
						break;
					case EField.TIME_PICKER:
						value = field.value.toFormat("HH:mm:ss");
						break;
					case EField.DATE_TIME_PICKER:
						value = DateService.Format.IsoStringDate({
							date: field.value,
						});
						break;
					case EField.TEXT:
					case EField.TEXT_AREA:
					case EField.NUMERIC:
						value = trim(field.value) === "" ? null : field.value;
						break;
					case EField.CHECKBOX:
						value = field.value ?? false;
						break;

					default:
						value = field.value;
				}

				return {
					op: field.isMultiSelect ? field.operation : EnumApiPatchOperation.REPLACE,
					path: `/${field.key}`,
					value,
				};
			})
	);
};

export const customFieldValueToCustomFieldValueRead = (field: CustomFieldValue): CustomFieldValueRead => {
	// Customfield without item
	if (!KindFieldLists.includes(field.kind)) {
		return customFieldBasicValueToCustomFieldValueRead(field);
	}

	// Customfield (fieldList) with items
	return customFieldListValueToCustomFieldValueRead(field);
};

const mapBulkActionCustomFieldsToPatch = (field: UseFormPickFields): Array<TJSONPatch> => {
	return (
		toPairs(field)
			.map((val) => {
				return {
					key: val[0],
					...val[1],
					isCustomField: false,
				};
			})
			// Remove not checked field & tags
			.filter((field) => field.isChecked)
			// Remaping to fit customFieldValueToCustomFieldValueRead function
			.map((field) => {
				if (field.kindCustomField === "date") {
					return {
						...field,
						value: field.value ? DateTime.fromFormat(field.value, ISO_DATE_FORMAT) : null,
					};
				}

				if (field.kindCustomField === "select" || field.kindCustomField === "radio") {
					return {
						...field,
						value: field.value?.key ?? null,
					};
				}

				return field;
			})
			// Remap as TJSONPatch instance
			.map((field) => {
				return {
					op: field.isMultiSelect ? field.operation : EnumApiPatchOperation.REPLACE,
					path: `${PATCH_PREFIX_CUSTOM_FIELD}${field.key}`,
					value: customFieldValueToCustomFieldValueRead({ kind: field.kindCustomField ?? "text", value: field.value }),
				};
			})
	);
};

const mapBulkActionFormForPatch = (useFormSubmit: TUseFormBulkAction): Array<TJSONPatch> => {
	return [...mapBulkActionBusinessFieldsToPatch(useFormSubmit.staticField), ...mapBulkActionCustomFieldsToPatch(useFormSubmit.customField)];
};

const getStaticPropertiesPatch = (
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
	sieveKeys: Array<string>,
) => {
	let filterString = "";
	let excludedIds = [];
	if (filters) {
		filterString = sieveStringFiltersPageList(sieveKeys, filters.search ?? "", filters.queryBuilderString ?? "");
	}

	if (!isNoStepSelect) {
		excludedIds = rows
			.filter((row: any) => {
				return !selectedItems.includes(row.id);
			})
			.map((row) => {
				return row.id;
			});
	}
	return {
		filterString,
		excludedIds,
	};
};

export type TPatchFilter = {
	filterString: string;
	excludedIds: Array<any>;
};

const fetchActionMiddleware = async <T>(
	filters: TFilter | undefined,
	isNoStepSelect: boolean,
	rows: GridRowsProp,
	selectedItems: Array<string>,
	cb: (patchFilters: TPatchFilter) => Promise<T>,
	sieveKeys: Array<string>,
): Promise<T | ErrorAPI> => {
	try {
		const patchFilters = BulkActionHelper.getStaticPropertiesPatch(filters, isNoStepSelect, rows, selectedItems, sieveKeys);

		return Promise.resolve(await cb(patchFilters));
	} catch (e: any) {
		if (e.status == 422) {
			return Promise.resolve(new ErrorAPI(e.status, e.message));
		}

		return Promise.reject(new ErrorAPI(e.status, e.message));
	}
};

const BulkActionHelper = {
	actionsObject,
	mapBulkActionFormForPatch,
	getStaticPropertiesPatch,
	fetchActionMiddleware,
	getTagsPatch,
};

export default BulkActionHelper;
