import type { LiteTagRead, SessionRead, SessionWrite } from "@api";
import type { SessionModalForm } from "@components";
import {
	type ActionItem,
	type CustomFieldForm,
	type CustomFieldsValuesForm,
	type TagProps,
	customFieldValuesReadToCustomFieldsValuesForm,
	customFieldsValuesFormToCustomFieldValuesRead,
	dateTimeToISO,
	emptyToUndefined,
	getDateTime,
	isoDurationToString,
	stringISOToDateTime,
	stringISOToDateTimeNullable,
	stringToIsoDuration,
} from "@key4-front-library/core";
import { toPairs } from "lodash";

export const sessionFormToSessionWrite = (form: CustomFieldForm<SessionModalForm>, session?: SessionRead): SessionWrite => {
	const secondariesTagsId: Array<string> = [];
	for (const tag of toPairs(form.tags)) {
		const tags = tag[1];
		for (const tag of tags) {
			secondariesTagsId.push(tag.value);
		}
	}

	const startDate = form.startDate && form.startTime ? dateTimeToISO(getDateTime(form.startDate, form.startTime)) : undefined;
	let endDate = form.startDate && form.endTime ? dateTimeToISO(getDateTime(form.startDate, form.endTime)) : undefined;

	if (form.isMultiday) {
		endDate = form.endDate && form.endTime ? dateTimeToISO(getDateTime(form.endDate, form.endTime)) : undefined;
	}

	return {
		roomId: form.roomId ?? undefined,
		code: emptyToUndefined(form.code),
		startDate: startDate,
		endDate: endDate,
		status: form.status,
		publicationDate: form.publicationDate ? dateTimeToISO(form.publicationDate) : undefined,
		title: form.title,
		isPrivate: form.isPrivate,
		description: emptyToUndefined(form.description),
		organizedBy: emptyToUndefined(form.organizedBy),
		expectedAudience: form.expectedAudience !== "" ? Number.parseInt(form.expectedAudience) : undefined,
		isEverywhere: false,
		isWholeProgramme: false,
		isFullDay: false,
		primaryTagId: emptyToUndefined(form.primaryTagId),
		duration: form.duration !== "" ? stringToIsoDuration(form.duration) : undefined,
		tagIds: secondariesTagsId,
		customFieldValues: customFieldsValuesFormToCustomFieldValuesRead(form.customFields),
		shouldApplySessionTemplate: form.shouldApplySessionTemplate,
		order: session?.order,
	};
};

export const sessionReadToSessionForm = (
	session: SessionRead,
	customFields: CustomFieldsValuesForm,
	tags: Array<LiteTagRead> = [],
): CustomFieldForm<SessionModalForm> => {
	const secondariesTagsId: Record<string, Array<ActionItem<TagProps>>> = {};

	for (const tag of tags) {
		secondariesTagsId[tag.tagTypeId] = [
			...(secondariesTagsId[tag.tagTypeId] ?? []),
			{
				value: tag.id,
				name: tag.label,
				component: {
					backgroundColor: tag.backgroundColor,
					borderColor: tag.borderColor,
					fontColor: tag.fontColor,
					children: tag.label,
				},
			},
		];
	}

	let isMultiday = false;
	if (session.startDate && session.endDate) {
		isMultiday = stringISOToDateTime(session.startDate).set({ hour: 0, minute: 0 }) < stringISOToDateTime(session.endDate).set({ hour: 0, minute: 0 });
	}

	return {
		code: session.code ?? "",
		primaryTagId: session.primaryTag?.id ?? "",
		organizedBy: session.organizedBy ?? "",
		title: session.title ?? "",
		description: session.description ?? "",
		isPrivate: session.isPrivate,
		expectedAudience: session.expectedAudience ? session.expectedAudience.toString() : "",
		roomId: session.room?.id ?? null,
		isMultiday: isMultiday,
		startDate: stringISOToDateTimeNullable(session.startDate),
		startTime: stringISOToDateTimeNullable(session.startDate),
		endDate: stringISOToDateTimeNullable(session.endDate),
		endTime: stringISOToDateTimeNullable(session.endDate),
		duration: isoDurationToString(session.duration),
		status: session.status,
		publicationDate: stringISOToDateTimeNullable(session.publicationDate),
		tags: secondariesTagsId,
		shouldApplySessionTemplate: false,
		customFields: customFieldValuesReadToCustomFieldsValuesForm(customFields, session.customFieldValues),
	};
};
