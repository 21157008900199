import { useChairQualities } from "@api";
import { ActivityForm, type ChairModalForm } from "@components";
import { type ActionItem, type TypographyProps, type UserInformationProps, useContextModule } from "@key4-front-library/core";
import { tagsReadToSelectItems } from "@mappers";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

interface ChairFormProps {
	isEditMode: boolean;
	userInformation?: UserInformationProps;
}

export const ChairForm = (props: ChairFormProps): React.ReactNode => {
	const { isEditMode, userInformation } = props;

	const { client, event } = useContextModule();

	const [chairQualitiesOptions, setChairQualitiesOptions] = useState<Array<ActionItem<TypographyProps>>>([]);
	const form = useFormContext<ChairModalForm, undefined>();
	const chairQualitiesQuery = useChairQualities(client.id, event.id);

	useEffect(() => {
		if (chairQualitiesQuery?.data?.data) {
			setChairQualitiesOptions(tagsReadToSelectItems(chairQualitiesQuery?.data?.data));
			if (!isEditMode) {
				form.setValue("qualityId", chairQualitiesQuery?.data?.data?.[0]?.id ?? "");
			}
		}
	}, [chairQualitiesQuery?.data?.data, form.setValue, isEditMode]);

	return <ActivityForm isEditMode={isEditMode} qualitiesOptions={chairQualitiesOptions} userInformation={userInformation} />;
};
