import type { ActivityInvitationStatus } from "@api";
import type { Theme } from "@mui/material";
import { t } from "i18next";

export const getActivityStatus = (theme: Theme): Record<ActivityInvitationStatus, { name: string; color: string }> => ({
	unsent: { color: theme.palette.grey[700], name: t("activityStatus.unsent") },
	declined: { color: theme.palette.error.main, name: t("activityStatus.declined") },
	replaced: { color: theme.palette.error.main, name: t("activityStatus.replaced") },
	cancelled: { color: theme.palette.neutral.main, name: t("activityStatus.cancelled") },
	waitingForAnswer: { color: theme.palette.grey[500], name: t("activityStatus.waitingForAnswer") },
	accepted: { color: theme.palette.success.main, name: t("activityStatus.accepted") },
});
