import { useSession } from "@api";
import { GeneralSectionSession, RoomSectionSession, ScheduleSectionSession } from "@components";
import { GridLabelsTabCustomField, Stack, useContextModule } from "@key4-front-library/core";
import { useParams } from "react-router-dom";

export const SessionGridLabelsTab = (): React.ReactNode => {
	const { sessionId = "" } = useParams();
	const { client, event } = useContextModule();
	const sessionQuery = useSession(client.id, event.id, sessionId, { queryStrings: [{ key: "includeCustomFields", value: "true" }] });

	if (!sessionQuery.data) {
		return undefined;
	}

	return (
		<Stack spacing={2}>
			<GeneralSectionSession />
			<RoomSectionSession />
			<ScheduleSectionSession />
			<GridLabelsTabCustomField module="programme" scope="session" id={sessionId} customFieldValues={sessionQuery.data.customFieldValues} />
		</Stack>
	);
};
