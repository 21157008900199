import type { BulkSessionPublishWrite, BulkSessionStatusWrite, LiteTagRead, SessionRead, SessionReadV1, SessionWrite, TagRead, TokenRead } from "@api";
import { type Paginated, type PatchPayload, type PathEntity, type ServiceArgs, get, getPaginated, patch, postCreate, put } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "sessions", id }];

interface GetSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSession = async (args: GetSessionArgs): Promise<SessionRead> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};

interface GetSessionV1Args extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionV1 = async (args: GetSessionV1Args): Promise<SessionReadV1> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};

interface GetSessionsTagArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
}

export const getSessionsTags = async (args: GetSessionsTagArgs): Promise<Paginated<TagRead>> => {
	return await getPaginated({
		module,
		entities: [...rootEntities(), { entity: "tags" }],
		...args,
	});
};

interface GetSessionTagArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionTags = async (args: GetSessionTagArgs): Promise<Paginated<LiteTagRead>> => {
	const { sessionId, ...others } = args;
	return await getPaginated({
		module,
		entities: [...rootEntities(sessionId), { entity: "tags" }],
		...others,
	});
};

interface PostSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: SessionWrite;
}

export const postSession = async (args: PostSessionArgs): Promise<string> => {
	const { body, ...others } = args;
	return await postCreate<SessionWrite>(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};

interface PutSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: SessionWrite;
}

export const putSession = async (args: PutSessionArgs): Promise<boolean> => {
	const { body, sessionId, ...others } = args;
	return await put<SessionWrite>(
		{
			module,
			entities: rootEntities(sessionId),
			...others,
		},
		body,
	);
};

interface GetSessionTokensArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionTokens = async (args: GetSessionTokensArgs): Promise<Paginated<TokenRead>> => {
	const { sessionId, ...others } = args;
	return await getPaginated({
		module,
		entities: [...rootEntities(sessionId), { entity: "tokens" }],
		...others,
	});
};

interface PatchSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: PatchPayload;
}

export const patchSessionBulk = async (args: PatchSessionArgs): Promise<boolean> => {
	const { body, ...others } = args;
	return await patch(
		{
			module,
			entities: [...rootEntities(), { entity: "bulk" }],
			...others,
		},
		body,
	);
};

interface PutSessionBulkStatusArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BulkSessionStatusWrite;
}

export const putSessionBulkStatus = async (args: PutSessionBulkStatusArgs): Promise<boolean> => {
	const { body, ...others } = args;
	return await put<BulkSessionStatusWrite>(
		{
			module,
			entities: [...rootEntities(), { entity: "bulk" }, { entity: "status" }],
			...others,
		},
		body,
	);
};

interface PutSessionBulkPublishArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	body: BulkSessionPublishWrite;
}

export const putSessionBulkPublish = async (args: PutSessionBulkPublishArgs): Promise<boolean> => {
	const { body, ...others } = args;
	return await put<BulkSessionPublishWrite>(
		{
			module,
			entities: [...rootEntities(), { entity: "bulk" }, { entity: "publish" }],
			...others,
		},
		body,
	);
};
