import i18n, { t } from "i18next";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProgrammeController from "@application/Controllers/ProgrammeController";
import SessionsGrid from "@application/components/sessions/SessionsGrid";
import { ESelect } from "@application/enum/SelectList.enum";
import HelpersApp from "@application/helpers";
import DateHelper from "@application/helpers/date.helper";
import HooksApp from "@application/hooks";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import SearchList from "@infrastructure/components/interface/search/SearchList";
import useLocalStorage from "@infrastructure/hooks/localStorage.hook";
import usePageList from "@infrastructure/hooks/pageList.hook";
import useSearchList from "@infrastructure/hooks/searchList.hook";
import {
	ButtonProgrammeExportListTemplates,
	ConfirmationModal,
	DebugConfig,
	EBulkActionEntity,
	EnumSearchFilterScope,
	type ErrorAPI,
	type IButtonProgrammeExportListTemplatesItem,
	ProgrammeExportRead,
	QueryBuilderHelper,
	Services,
	TAGS_MARKER,
	TitleOld,
	customFieldSessionsIgnoreScope,
	getFormattedCustomFields,
	getQueryBuilderDefinition,
	localStorageQuickSearchKey,
	sessionAuthorizedForeignKeys,
	sessionDisabledKeys,
	statusInvitationParticipantObject,
	statusSessionObject,
	useBulkActionStore,
	useContextModule,
	useCustomFieldContext,
	useProgrammeExports,
	useToggle,
	downloadProgrammeExport,
} from "@key4-front-library/core";
import ButtonBulkAction from "@key4-front-library/core/Bo/Components/Button/ButtonBulkAction";
import DebugQueryFilter from "@key4-front-library/core/Bo/Components/DebugQueryFilter";
import DefaultModal from "@key4-front-library/core/Bo/Components/Modal/DefaultModal";
import { dtoDefinitionListToTAdvancedSearchFieldDefinitionList } from "@mappers";
import { Box, Button, Grid, Stack } from "@mui/material";

import type { TAutoCompleteBulletColorItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlAutocompleteBulletColor";
import type { Fields } from "@react-awesome-query-builder/ui";

import { EditPresentationsSchedule, type SessionEditSchedule } from "@application/Dialogs";
import type { ISelectableListItemsDefinition } from "@application/components/_common/QuickSearch/components/SelectableListItems/interface/ISelectableListItemsDefinition.interface";
import type ISelectionState from "@application/components/_common/QuickSearch/interface/ISelectionState.interface";
import { SessionModal } from "@components";
const initialState: Array<ISelectionState> = [];
const localStoragePrefixKey = "search";

const PageListSessions = () => {
	const debugQueryFilter = DebugConfig.QueryFilter;
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const bulkActionStore = useBulkActionStore();

	const { client, event } = useContextModule();
	const { readList: readListRoom } = HooksApp.RoomHook.useEntity();
	const { data: rooms } = readListRoom();
	const { readListTagsType } = HooksApp.SessionHook.useEntity();
	const { data: tagTypesWithTags } = readListTagsType();

	const { data: listProgrammeExportTemplate } = useProgrammeExports(client.id, event.id);

	const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
	const [sessionId, setSessionId] = useState<string>();
	const { isToggle: isOpenSessionModal, toggle: toggleOpenSessionModal } = useToggle();
	const [isExportProgrammeModalOpen, setIsExportProgrammeModalOpen] = useState(false);

	const [isOpenConfirmationModalFullExport, setIsOpenConfirmationModalFullExport] = useState(false);
	const [confirmationModalFullExportLoading, setConfirmationModalFullExportLoading] = useState(false);

	const [isProgrammeExportButtonDisabled, setIsProgrammeExportButtonDisabled] = useState(true);
	const [programmeExportListTemplates, setProgrammeExportListTemplates] = useState<Array<IButtonProgrammeExportListTemplatesItem>>([]);

	const { isToggle: isEditPresentationsScheduleOpen, toggle: toggleIsEditPresentationsScheduleOpen } = useToggle(false);
	const [selectedSessionEditSchedule, setSelectedSessionEditSchedule] = useState<SessionEditSchedule | undefined>(undefined);

	const [quickSearchSavedState] = useLocalStorage<Array<ISelectionState>>(`${localStoragePrefixKey}-${localStorageQuickSearchKey}`, initialState);

	const [error] = useState<ErrorAPI | undefined>(undefined);
	const [isFieldsLoaded, setIsFieldsLoaded] = useState<boolean>(false);
	const [rowsCount, setRowsCount] = useState<number>(0);

	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});
	const [isRefreshSessions, setIsRefreshSessions] = useState<boolean>(false);

	const [quickSearchFieldsDefinition, setQuickSearchFieldsDefinition] = useState<Array<ISelectableListItemsDefinition>>([]);

	const {
		search,
		fields,
		setFields,
		queryBuilderString,
		handleSearchChange,
		handleQueryBuilderChange,
		openSaveNewQueryModale,
		setOpenSaveNewQueryModale,
		newQueryFilterId,
		setNewQueryFilterId,
		handleOpenSaveNewQueryModaleClick,
		searchFilterQueriesList,
		setSearchFilterQueriesList,
	} = useSearchList();

	const { getSearchFilterQueriesList, handleCreateQuerySearchFilterClick, handleUpdateQueryFilterClick, handleDeleteQueryFilterClick } = usePageList(
		setNewQueryFilterId,
		setSearchFilterQueriesList,
		enqueueSnackbar,
		client,
		event,
		setOpenSaveNewQueryModale,
		EnumSearchFilterScope.SESSION,
	);

	const { customForms } = useCustomFieldContext();

	const initComponent = useCallback(async () => {
		await getSearchFilterQueriesList(client.id, event.id);
	}, [client.id, event.id, getSearchFilterQueriesList]);

	useEffect(() => {
		void initComponent();
	}, [event, initComponent]);

	useEffect(() => {
		if (!listProgrammeExportTemplate?.data || listProgrammeExportTemplate.data.length === 0) {
			return;
		}

		setProgrammeExportListTemplates(
			listProgrammeExportTemplate.data.map((template) => {
				return {
					label: template.name,
					onClick: () => handleExportProgrammeTemplateClick(template),
				};
			}),
		);

		setIsProgrammeExportButtonDisabled(false);
	}, [listProgrammeExportTemplate]);

	const getMappedCustomFields = useMemo(
		() =>
			getFormattedCustomFields(
				customForms,
				fields ?? {},
				customFieldSessionsIgnoreScope,
				{
					definition: [],
					fieldName: "",
					disabledKeys: sessionDisabledKeys,
					authorizedForeignKeys: [],
				},
				event,
			),
		[customForms, fields],
	);

	useEffect(() => {
		if (tagTypesWithTags) {
			const fetchData = async (): Promise<Fields> => {
				const definition = await Services.ProgrammeService.getDefinition();

				return getQueryBuilderDefinition(
					{
						definition: dtoDefinitionListToTAdvancedSearchFieldDefinitionList(definition),
						fieldName: "Session",
						authorizedForeignKeys: sessionAuthorizedForeignKeys,
						disabledKeys: sessionDisabledKeys,
						selectList: [
							// add here other Select fields
							{
								resource: ESelect.SESSION_STATUS,
								listValues: Object.values(statusSessionObject).map((e) => ({
									value: e.value,
									title: t(e.label).toString(),
								})),
							},
							{
								resource: ESelect.INVITATION_STATUS,
								listValues: Object.values(statusInvitationParticipantObject).map((e) => ({
									value: e.value,
									title: t(e.label).toString(),
								})),
							},
							...tagTypesWithTags.map((tag) => ({
								// add tag values
								resource: TAGS_MARKER + tag.label,
								listValues: tag.tags
									? tag.tags.map((e) => ({
											value: e.id,
											title: e.label,
										}))
									: [],
							})),
						],
						tags: tagTypesWithTags,
					},
					event,
				);
			};

			fetchData()
				.then((_response) => {
					setFields({
						...fields,
						...QueryBuilderHelper.getTranslatedFields(_response),
					});
				})
				.catch((error) => {
					console.error("fetchData getQueryBuilderDefinition ERROR", error);
				});
		}
	}, [event, customForms, tagTypesWithTags]);

	useEffect(() => {
		if (!isFieldsLoaded && fields) {
			setFields({
				...fields,
				...getMappedCustomFields,
			});
			setIsFieldsLoaded(true);
		}
	}, [fields, isFieldsLoaded]);

	useEffect(() => {
		const definitions: Array<ISelectableListItemsDefinition> = [];

		const initQuickSearchCollections = () => {
			definitions.push({
				key: "rooms",
				name: t("old.common.quickSearch.rooms"),
				collection: rooms,
				searchKey: "room.id",
				keyPropertyName: "id",
				labelPropertyName: "name",
				addEmptyRow: true,
				order: 2,
			} as ISelectableListItemsDefinition);

			definitions.push({
				key: "dates",
				name: t("old.common.quickSearch.dates"),
				collection: DateHelper.getDatesListRange(event.startDate, event.endDate),
				searchKey: "startDate",
				keyPropertyName: "key",
				labelPropertyName: "label",
				addEmptyRow: true,
				order: 1,
			} as ISelectableListItemsDefinition);

			const statusList: Array<TAutoCompleteBulletColorItem> = HelpersApp.SessionHelper.getStatusSessionListItems(t);

			definitions.push({
				key: "status",
				name: t("old.common.quickSearch.status"),
				collection: statusList.map((status) => {
					return {
						key: status.key,
						label: status.label,
						color: status.color,
					};
				}),
				searchKey: "status",
				keyPropertyName: "key",
				labelPropertyName: "label",
				addEmptyRow: false,
				order: 0,
			} as ISelectableListItemsDefinition);
			if (tagTypesWithTags) {
				tagTypesWithTags.forEach((tagType, index) => {
					const tagTypeLabelFormated = tagType.label[0].toLowerCase() + tagType.label.slice(1).replace(/\s/g, "");
					const name = i18n.exists(`old.common.quickSearch.${tagTypeLabelFormated}`) ? t(`old.common.quickSearch.${tagTypeLabelFormated}`) : tagType.label;

					definitions.push({
						key: tagType.id,
						name,
						collection: tagType.tags
							? tagType.tags.map((tag) => {
									return {
										key: tag.id,
										label: tag.label,
									};
								})
							: [],
						searchKey: "tags.id",
						keyPropertyName: "key",
						labelPropertyName: "label",
						addEmptyRow: false,
						order: 3 + index,
					} as ISelectableListItemsDefinition);
				});
			}
			setQuickSearchFieldsDefinition(definitions);
		};
		if (rooms && tagTypesWithTags) {
			initQuickSearchCollections();
		}
	}, [rooms, tagTypesWithTags, event, t]);

	const handleModalClose = (): void => {
		setIsOpenConfirmationModal(false);
	};

	const handleFullExport = async () => {
		await ProgrammeController.getExport(client.id, event.id, event.key);
		setIsOpenConfirmationModalFullExport(false);
		setConfirmationModalFullExportLoading(false);
	};

	const handleExportProgrammeTemplateClick = async (document: ProgrammeExportRead) => {
		setIsProgrammeExportButtonDisabled(true);
		setIsExportProgrammeModalOpen(true);
		await downloadProgrammeExport({
			clientId: client.id,
			operationId: event.id,
			programmeExportId: document.id,
		})
			.catch(() => enqueueSnackbar(t("old.common.errors.generic"), { variant: "error" }))
			.finally(() => setIsProgrammeExportButtonDisabled(false));
	};

	if (error) {
		throw error;
	}

	const handleSessionCallbackSubmit = (sessionEditSchedule?: SessionEditSchedule) => {
		setIsRefreshSessions((prev) => !prev);
		if (sessionEditSchedule) {
			setSelectedSessionEditSchedule(sessionEditSchedule);
			toggleIsEditPresentationsScheduleOpen();
		}
	};

	const handleSessionDoubleClick = (sessionId: string) => {
		setSessionId(sessionId);
		toggleOpenSessionModal();
	};

	return (
		<>
			{isOpenSessionModal && (
				<SessionModal isOpened={isOpenSessionModal} onClose={toggleOpenSessionModal} sessionId={sessionId} onCallbackSubmit={handleSessionCallbackSubmit} />
			)}
			<TitleOld
				title={t("old.programme.sessions.title")}
				buttons={[
					{
						customElement: <ButtonProgrammeExportListTemplates items={programmeExportListTemplates} disabled={isProgrammeExportButtonDisabled} />,
						icon: { iconName: "file-export" },
						label: t("old.form.buttons.programmeExport"),
						handleClick: () => {},
					},
					{
						label: t("old.form.buttons.fullExport"),
						icon: { iconName: "file-xmark", color: "black" },
						color: "info",
						sx: {
							backgroundColor: "white !important",
						},
						sxFont: {
							color: "black !important",
						},
						handleClick: () => {
							setIsOpenConfirmationModalFullExport(true);
						},
					},
					{
						label: t("old.programme.sessions.buttons.add"),
						icon: { iconName: "add" },
						color: "primary",
						handleClick: () => {
							setSessionId(undefined);
							toggleOpenSessionModal();
						},
					},
				]}
			/>
			<AppBox>
				<Stack>
					<Stack py="1rem">
						{debugQueryFilter && <DebugQueryFilter queryBuilderString={queryBuilderString} onChangeQueryBuilder={handleQueryBuilderChange} />}
						<Stack>
							{fields && searchFilterQueriesList && (
								<SearchList
									onSearchChange={handleSearchChange}
									onQueryBuilderChange={handleQueryBuilderChange}
									fields={fields}
									searchFilterQueriesList={searchFilterQueriesList}
									openSaveNewQueryModale={openSaveNewQueryModale}
									onOpenSaveNewQueryModaleClick={handleOpenSaveNewQueryModaleClick}
									newQueryFilterId={newQueryFilterId ?? null}
									onCreateQuerySearchFilterClick={handleCreateQuerySearchFilterClick}
									onUpdateQueryFilterClick={handleUpdateQueryFilterClick}
									onDeleteQueryFilterClick={handleDeleteQueryFilterClick}
									quickSearchFieldsDefinition={quickSearchFieldsDefinition}
									localStoragePrefixKey={localStoragePrefixKey}
								/>
							)}
						</Stack>
						<Stack direction={"row"} mt={2} justifyContent={"flex-start"}>
							<Stack>
								<ButtonBulkAction
									isDisabled={rowsCount === 0}
									onClick={() => {
										localStorage.removeItem("bulkActionStore");
										bulkActionStore.init(EBulkActionEntity.SESSION, {
											search,
											queryBuilderString,
										});
										bulkActionStore.setActiveStep(1);
										navigate(`../${EBulkActionEntity.SESSION}/bulkaction/1`);
									}}
								/>
							</Stack>
						</Stack>
					</Stack>

					<Grid item xs={12}>
						<SessionsGrid
							onDoubleClick={handleSessionDoubleClick}
							setRowsCount={(count: number) => {
								setRowsCount(count);
							}}
							queryBuilderString={queryBuilderString}
							search={search}
							isRefreshSessions={isRefreshSessions}
							isWaitingForQueryBuilderChange={quickSearchSavedState.length !== 0}
						/>
					</Grid>
				</Stack>
			</AppBox>
			<ConfirmationModal
				open={isOpenConfirmationModal}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleModalClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
			<ConfirmationModal
				open={isOpenConfirmationModalFullExport}
				text={t("old.programme.sessions.fullExport.modalText")}
				handleModaleClose={() => {
					setIsOpenConfirmationModalFullExport(false);
				}}
				handleAction={() => {
					setConfirmationModalFullExportLoading(true);
					handleFullExport();
				}}
				maxWidth={"sm"}
				isLoading={confirmationModalFullExportLoading}
			/>

			<DefaultModal
				title={t("old.programme.sessions.programmeTemplateExport.modal.title")}
				open={isExportProgrammeModalOpen}
				actions={
					<Button
						variant="contained"
						color="secondary"
						onClick={() => {
							setIsExportProgrammeModalOpen(false);
						}}
					>
						{t("old.common.button.confirm")}
					</Button>
				}
			>
				<Box>{t("old.programme.sessions.programmeTemplateExport.modal.content")}</Box>
			</DefaultModal>

			<EditPresentationsSchedule
				sessionEditSchedule={selectedSessionEditSchedule}
				isOpen={isEditPresentationsScheduleOpen}
				onClose={() => {
					toggleIsEditPresentationsScheduleOpen();
				}}
			/>
		</>
	);
};

export default PageListSessions;
