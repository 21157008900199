import ConfigurationsApp from "@application/Configurations";
import {
	EnumProgrammeExportDynamicFormKey,
	EnumProgrammeExportSectionMoveDirection,
	EnumProgrammeExportStaticSectionKey,
} from "@application/Enums/ProgrammeExportEnum";
import ProgrammeExportTabs from "@application/components/ProgrammeExport/ProgrammeExportTabs";
import HelpersApp from "@application/helpers";
import ProgrammeExportHook from "@application/hooks/ProgrammeExportHook";
import SnackBarHook from "@application/hooks/SnackBarHook";
import {
	ButtonCancel,
	ButtonSave,
	ConfirmationModal,
	type DialogComponentProps,
	DialogOld,
	EConfirmationModalAction,
	useProgrammeExportCreate,
	useProgrammeExportUpdate,
} from "@key4-front-library/core";
import type { TypeUseFormListForms } from "@key4-front-library/core";
import DialogTitle from "@key4-front-library/core/Bo/Components/DialogTitle";
import { DialogActions, DialogContent, Skeleton, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

const staticSectionKey = EnumProgrammeExportStaticSectionKey.GeneralInformationSection;
const dynamicFormKey = EnumProgrammeExportDynamicFormKey.DocumentSectionsForm;
const sectionModel = ConfigurationsApp.ProgrammeExportConfiguration.defaultProgrammeExportSectionDto;
const defaultTabKey = EnumProgrammeExportStaticSectionKey.GeneralInformationSection;

enum Action {
	CREATED = "created",
	UPDATED = "updated",
	INVALID = "invalid",
}

export interface PropsContainerProgrammeExportModal {
	clientId: string;
	eventId: string;
	id: string | null;
}

const ContainerProgrammeExportModal = ({ payload, open, onClose }: DialogComponentProps<PropsContainerProgrammeExportModal>) => {
	const { clientId, eventId, id } = payload;

	const [currentProgrammeExportId, setCurrentProgrammeExportId] = useState<string | null>(id);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
	const [activeTabKey, setActiveTabKey] = useState<string | null>(defaultTabKey);
	const [isOpenConfirmationModalDelete, setIsOpenConfirmationModalDelete] = useState<boolean>(false);
	const [currentSectionKeyToDelete, setCurrentSectionKeyToDelete] = useState<string | undefined>(undefined);

	const { sendSuccess, sendError } = SnackBarHook.useSnackBar();

	const { read } = ProgrammeExportHook.useEntity();
	const { data, isLoading, isFetching, refetch } = read(clientId, eventId, currentProgrammeExportId);

	const create = useProgrammeExportCreate({
		onSuccess: (createdId: string) => {
			if (!currentProgrammeExportId && createdId) {
				setCurrentProgrammeExportId(createdId);
			}
			sendSuccess(t([translationActionsKey, Action.CREATED].join(".")));
		},
		onError: (error) => sendError(error.message),
	});

	const update = useProgrammeExportUpdate({
		onSuccess: async () => {
			sendSuccess(t([translationActionsKey, Action.UPDATED].join(".")));
		},
		onError: (error) => sendError(error.message),
	});

	const translationNameSpace = "old.programme.programmeExport";

	const translationBaseKey: string = [translationNameSpace, "modal"].join(".");
	const translationActionsKey: string = [translationBaseKey, "actions"].join(".");
	const newSectionKey = [translationBaseKey, "sections", "newSection"].join(".");
	const translations = {
		dialogTitle: t([translationBaseKey, "title", !id ? "create" : "update"].join(".")),
		confirmationDeletePrompt: t([translationBaseKey, "confirmationDeletePrompt"].join(".")),
		newSection: t(newSectionKey),
		deletedSection: t([translationBaseKey, "sections", "deletedSection"].join(".")),
	};

	const formMethods = useForm({
		mode: "onSubmit",
		resolver: HelpersApp.ProgrammeExportHelper.getResolver(data?.useFormData ?? null),
	});

	const { handleSubmit: onSubmit, setValue } = formMethods;

	useEffect(() => {
		setCurrentProgrammeExportId(id);
	}, [id]);

	useEffect(() => {
		if (!isLoading && !isFetching && data?.useFormData) {
			HelpersApp.ProgrammeExportHelper.formsSetValues(data.useFormData, setValue);
		}
	}, [isLoading, isFetching]);

	const handleDialogButtonSaveClick = (mouseEvent: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setIsLoadingSubmit(true);
		onSubmit((form) => handleSubmit({ form, closeModal: true }), handleInvalid)(mouseEvent);
	};

	const changeTab = (key: string | null) => setActiveTabKey(key === "default" ? defaultTabKey : (key ?? null));

	const handleAddSection = () => onSubmit((form) => handleSubmit({ form, addSection: true }), handleInvalid)();

	const handleCloneSection = (key: string) => onSubmit((form) => handleSubmit({ form, cloneSection: true, key }), handleInvalid)();

	const handleMoveLeftSection = (key: string) =>
		onSubmit(
			(form) =>
				handleSubmit({
					form,
					moveSection: true,
					moveSectionDirection: EnumProgrammeExportSectionMoveDirection.Left,
					key,
				}),
			handleInvalid,
		)();

	const handleMoveRightSection = (key: string) =>
		onSubmit(
			(form) =>
				handleSubmit({
					form,
					moveSection: true,
					moveSectionDirection: EnumProgrammeExportSectionMoveDirection.Right,
					key,
				}),
			handleInvalid,
		)();

	const handleRemoveSection = (key: string) => {
		setCurrentSectionKeyToDelete(key);
		handleConfirmationModalDeleteOpen();
	};

	const handleConfirmationModalDeleteOpen = () => {
		setIsOpenConfirmationModalDelete(true);
	};

	const handleConfirmationModalDeleteClose = () => {
		setIsOpenConfirmationModalDelete(false);
	};

	const handleConfirmationModalDeleteAction = async () => {
		const key = currentSectionKeyToDelete;
		setCurrentSectionKeyToDelete(undefined);
		setIsOpenConfirmationModalDelete(false);
		changeTab(staticSectionKey);
		onSubmit((form) => handleSubmit({ form, removeSection: true, key }))();
	};

	const handleCreate = async (form: TypeUseFormListForms) => {
		return create.mutateAsync({
			clientId,
			operationId: eventId,
			body: HelpersApp.ProgrammeExportHelper.mapUseFormToDtoProgrammeExport(form),
		});
	};

	const handleUpdate = async (id: string, form: TypeUseFormListForms) => {
		return update.mutateAsync({
			clientId,
			operationId: eventId,
			programmeExportId: id,
			body: HelpersApp.ProgrammeExportHelper.mapUseFormToDtoProgrammeExport(form),
		});
	};

	const handleSubmit = async (props: {
		form: TypeUseFormListForms;
		closeModal?: boolean;
		addSection?: boolean;
		cloneSection?: boolean;
		moveSection?: boolean;
		moveSectionDirection?: EnumProgrammeExportSectionMoveDirection;
		removeSection?: boolean;
		key?: string;
	}) => {
		const { form, addSection = false, removeSection = false, cloneSection = false, moveSection = false, moveSectionDirection, key } = props;

		let currentTabKey: string | null = activeTabKey;

		if (addSection) {
			const newSectionKey = HelpersApp.ProgrammeExportHelper.getDynamicSectionKey(data?.sectionsCount ?? 0);
			currentTabKey = newSectionKey;
			if (!form[dynamicFormKey]) {
				form[dynamicFormKey] = {};
			}
			form[dynamicFormKey][newSectionKey] = {};
			setValue([dynamicFormKey, newSectionKey].join("."), {
				...sectionModel,
				name: t(translations.newSection),
			});
		}

		if (cloneSection && key) {
			const originalSection = form[dynamicFormKey][key];
			const cloneSectionKey = HelpersApp.ProgrammeExportHelper.getDynamicSectionKey(data?.sectionsCount ?? 0);
			currentTabKey = cloneSectionKey;
			form[dynamicFormKey][cloneSectionKey] = cloneDeep(originalSection);
		}

		if (moveSection && moveSectionDirection && key) {
			HelpersApp.ProgrammeExportHelper.moveSection(form, key, moveSectionDirection);
		}

		if (removeSection && key) {
			delete form[dynamicFormKey][key];
		}

		(!currentProgrammeExportId ? handleCreate(form) : handleUpdate(currentProgrammeExportId, form))
			.then(() => {
				refetch().then(() => {
					if (currentTabKey !== activeTabKey) {
						changeTab(currentTabKey);
					}
				});
			})
			.catch((error) => sendError(error))
			.finally(() => {
				setIsLoadingSubmit(false);
			});
	};

	const handleInvalid = () => {
		setIsLoadingSubmit(false);
		sendError(t([translationActionsKey, Action.INVALID].join(".")).toString());
	};

	return (
		<>
			<DialogOld isOpen={open}>
				<>
					<DialogTitle
						title={
							<Stack direction={"row"} spacing={1} alignItems={"center"}>
								<Stack>
									<Typography variant="h5">{translations.dialogTitle}</Typography>
								</Stack>
							</Stack>
						}
						onCloseClick={() => onClose()}
					/>
					<DialogContent>
						{!isLoading && !isFetching && data?.componentData && (
							<FormProvider {...formMethods}>
								<ProgrammeExportTabs
									activeTabKey={activeTabKey}
									onChangeTab={changeTab}
									onAddSectionClick={handleAddSection}
									onCloneSectionClick={handleCloneSection}
									onMoveLeftSectionClick={handleMoveLeftSection}
									onMoveRightSectionClick={handleMoveRightSection}
									onRemoveSectionClick={handleRemoveSection}
									tabsDefinitions={data.componentData}
								/>
							</FormProvider>
						)}
						{(isLoading || isFetching || !data?.componentData) && <Skeleton sx={{ width: "100%", height: 600 }} animation="wave" variant="rectangular" />}
					</DialogContent>
					<DialogActions>
						<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
							<ButtonCancel onClick={() => onClose()} />
							<ButtonSave isLoading={isLoadingSubmit || isFetching} onClick={handleDialogButtonSaveClick} />
						</Stack>
					</DialogActions>
				</>
			</DialogOld>

			{isOpenConfirmationModalDelete && (
				<ConfirmationModal
					open={isOpenConfirmationModalDelete}
					text={translations.confirmationDeletePrompt}
					action={EConfirmationModalAction.DELETE}
					handleAction={handleConfirmationModalDeleteAction}
					handleModaleClose={handleConfirmationModalDeleteClose}
					maxWidth={"sm"}
				/>
			)}
		</>
	);
};

export default ContainerProgrammeExportModal;
