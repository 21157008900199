import { zodResolver } from "@hookform/resolvers/zod";
import {
	Checkbox,
	DialogAdvanced,
	type DialogComponentProps,
	FormControl,
	Grid,
	GridLabel,
	type InvitationSettingsRead,
	getDialogAdvanced,
	getRhfCheckbox,
	useContextModule,
	useInvitationSettings,
	useInvitationSettingsUpdate,
	useSnackbar,
} from "@key4-front-library/core";
import type { UseQueryResult } from "@tanstack/react-query";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import type { InvitationSettingsForm } from "./InvitationSettings.type";
import { invitationSettingsDefaultValues, invitationSettingsSchema } from "./InvitationSettingsModal.configuration";

export interface InvitationSettingsModalProps {
	onCallbackSubmit?: () => void;
}

export const InvitationSettingsModal = ({ payload, open, onClose }: DialogComponentProps<InvitationSettingsModalProps>) => {
	const component = "invitationSettingsModal";

	const { client, event } = useContextModule();

	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
	const { enqueue } = useSnackbar();

	const invitationSettingsQuery: UseQueryResult<InvitationSettingsRead> = useInvitationSettings(client.id, event.id, {
		enabled: !!client.id && !!event.id,
	});

	const handleOnSuccess = async () => {
		setIsLoadingSubmit(false);
		enqueue({ type: "success", translation: t(`${component}`) });
		payload.onCallbackSubmit?.();
		await onClose();
	};

	const invitationSettingsUpdate = useInvitationSettingsUpdate({ onSuccess: handleOnSuccess, onError: () => setIsLoadingSubmit(false) });

	const handleDialogButtonSaveClick = () => {
		setIsLoadingSubmit(true);
		const formValues = form.getValues();
		invitationSettingsUpdate.mutate({
			clientId: client.id,
			eventId: event.id,
			body: {
				shouldDisplayConflictOfInterestQuestion: formValues.shouldDisplayConflictOfInterestQuestion ?? false,
				shouldDisplayConflictOfInterestDetailsQuestion: formValues.shouldDisplayConflictOfInterestDetailsQuestion ?? false,
			},
		});
	};

	const form = useForm<InvitationSettingsForm>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: invitationSettingsDefaultValues,
		resolver: zodResolver(invitationSettingsSchema),
	});

	const displayCoiQuestion = useWatch({ control: form.control, name: "shouldDisplayConflictOfInterestQuestion" });

	useEffect(() => {
		if (!displayCoiQuestion) {
			form.setValue("shouldDisplayConflictOfInterestDetailsQuestion", false);
		}
	}, [displayCoiQuestion, form.setValue]);

	useEffect(() => {
		if (invitationSettingsQuery.data && invitationSettingsQuery.isFetched) {
			form.reset(invitationSettingsQuery.data);
		}
	}, [invitationSettingsQuery.data, invitationSettingsQuery.isFetched, form.reset]);

	return (
		<DialogAdvanced
			{...getDialogAdvanced({
				type: "save",
				options: { isLoading: isLoadingSubmit || invitationSettingsQuery.isLoading, onCancelClick: onClose, onActionClick: handleDialogButtonSaveClick },
			})}
			open={open}
			title={{ variant: "h6", children: t(`${component}.title`) }}
			maxWidth={"sm"}
			fullWidth
		>
			<FormProvider {...form}>
				<Grid container spacing={2}>
					<GridLabel label={t(`${component}.shouldDisplayConflictOfInterestQuestion`)} gridSize={{ xs: 6 }}>
						<FormControl
							name={"shouldDisplayConflictOfInterestQuestion"}
							gridSize={{ xs: 6 }}
							render={(args) => <Checkbox {...getRhfCheckbox(args, { type: "default" })} />}
						/>
					</GridLabel>
					<GridLabel label={t(`${component}.shouldDisplayConflictOfInterestDetailsQuestion`)} gridSize={{ xs: 6 }}>
						<FormControl
							name={"shouldDisplayConflictOfInterestDetailsQuestion"}
							gridSize={{ xs: 6 }}
							render={(args) => <Checkbox {...getRhfCheckbox(args, { type: "default" })} disabled={!displayCoiQuestion} />}
						/>
					</GridLabel>
				</Grid>
			</FormProvider>
		</DialogAdvanced>
	);
};
