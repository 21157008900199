import type { InjectSessionData, SessionModalForm } from "@components";
import { durationZod } from "@helpers";
import {
	type CustomFieldForm,
	customFieldsZod,
	dateAndTimeInDateTime,
	dateNullableZod,
	dateValidator,
	superRefineRequired,
	timeNullableZod,
} from "@key4-front-library/core";
import { t } from "i18next";
import type { DateTime } from "luxon";
import { z } from "zod";

export const sessionDefaultValues = (injectSessionData: InjectSessionData = {}): CustomFieldForm<SessionModalForm> => ({
	code: "",
	primaryTagId: "",
	organizedBy: "",
	title: "",
	description: "",
	isPrivate: false,
	expectedAudience: "",
	roomId: null,
	isMultiday: false,
	startDate: null,
	startTime: null,
	endDate: null,
	endTime: null,
	duration: "",
	status: "draft",
	publicationDate: null,
	customFields: {},
	shouldApplySessionTemplate: false,
	...injectSessionData,
});

const baseSchema = z.object({
	code: z.string(),
	primaryTagId: z.string(),
	organizedBy: z.string(),
	title: z.string(),
	description: z.string(),
	isPrivate: z.boolean(),
	expectedAudience: z.string(),
	roomId: z.string().nullable(),
	isMultiday: z.boolean(),
	startDate: dateNullableZod(),
	startTime: timeNullableZod(),
	endDate: dateNullableZod(),
	endTime: timeNullableZod(),
	duration: durationZod(),
	status: z.string(),
	publicationDate: dateNullableZod(),
	tags: z.record(z.string(), z.array(z.object({ value: z.string() }))).optional(),
	customFields: customFieldsZod,
	shouldApplySessionTemplate: z.boolean(),
});

const multidayValidator = (
	startDate: DateTime | null,
	startTime: DateTime | null,
	endDate: DateTime | null,
	endTime: DateTime | null,
	ctx: z.RefinementCtx,
) => {
	// If one field is filled, the others are required
	if (startDate || startTime || endTime || endDate) {
		if (!startDate) {
			superRefineRequired(ctx, "startDate");
		}
		if (!startTime) {
			superRefineRequired(ctx, "startTime");
		}
		if (!endDate) {
			superRefineRequired(ctx, "endDate");
		}
		if (!endTime) {
			superRefineRequired(ctx, "endTime");
		}
	}

	if (
		dateValidator(startDate) &&
		dateValidator(startTime) &&
		dateValidator(endTime) &&
		dateValidator(endDate) &&
		endDate &&
		startDate &&
		startTime &&
		endTime &&
		dateAndTimeInDateTime(startDate, startTime) >= dateAndTimeInDateTime(endDate, endTime)
	) {
		// startDate > endDate
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t("form.validation.date.endDateMustBeSuperiorToStartDate"),
			path: ["endTime"],
		});
	}
};

const sameDayValidator = (startDate: DateTime | null, startTime: DateTime | null, endTime: DateTime | null, ctx: z.RefinementCtx) => {
	// If one field is filled, the others are required
	if (startDate || startTime || endTime) {
		if (!startDate) {
			superRefineRequired(ctx, "startDate");
		}
		if (!startTime) {
			superRefineRequired(ctx, "startTime");
		}
		if (!endTime) {
			superRefineRequired(ctx, "endTime");
		}
	}
	// startDate > endDate
	if (
		dateValidator(startDate) &&
		dateValidator(startTime) &&
		dateValidator(endTime) &&
		startDate &&
		startTime &&
		endTime &&
		dateAndTimeInDateTime(startDate, startTime) >= dateAndTimeInDateTime(startDate, endTime)
	) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			message: t("form.validation.date.endDateMustBeSuperiorToStartDate"),
			path: ["endTime"],
		});
	}
};

export const sessionSchema = baseSchema.superRefine((data, ctx) => {
	if (data.isMultiday === false) {
		sameDayValidator(data.startDate, data.startTime, data.endTime, ctx);
	} else {
		multidayValidator(data.startDate, data.startTime, data.endDate, data.endTime, ctx);
	}
});
