import ChairHook from "./ChairHook";
import ConvocationHook from "./ConvocationHook";
import RoomHook from "./RoomHook";
import SessionHook from "./SessionHook";
import SettingsHook from "./SettingsHook";
import SpeakerHook from "./SpeakerHook";

export * from "./presentations/PresentationHook";

const HooksApp = {
	ChairHook,
	ConvocationHook,
	RoomHook,
	SessionHook,
	SettingsHook,
	SpeakerHook,
};

export default HooksApp;
