import { useSession, useSessionTokens } from "@api";
import {
	Button,
	Checkbox,
	ConfirmationModal,
	EConfirmationModalAction,
	Grid,
	GridLabel,
	Link,
	PaperTitle,
	Services,
	Stack,
	Typography,
	getButton,
	sortToQueryString,
	useContextModule,
	useToggle,
} from "@key4-front-library/core";
import { tokenReadToExternalLink } from "@mappers";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const GeneralSectionSession = (): React.ReactNode => {
	const component = "generalSectionSession";

	const { sessionId = "" } = useParams();
	const { client, event } = useContextModule();

	const [externalLink, setExternalLink] = useState<string>();
	const { isToggle: isOpenDeleteConfirmationModal, toggle: toggleOpenDeleteConfirmationModal } = useToggle();

	const sessionQuery = useSession(client.id, event.id, sessionId);
	const sessionTokensQuery = useSessionTokens(client.id, event.id, sessionId, { queryStrings: sortToQueryString([{ field: "expireAt", sort: "desc" }]) });

	useEffect(() => {
		if (sessionTokensQuery.data?.data?.[0]) {
			setExternalLink(tokenReadToExternalLink(sessionTokensQuery.data.data[0], event.domain));
		}
	}, [sessionTokensQuery, sessionTokensQuery.data, event.domain]);

	const handleExternalLinkDeleteClick = async () => {
		if (sessionTokensQuery.data?.data?.[0]) {
			await Services.Events.Programme.TokenService.revokeToken(client.id, event.id, sessionTokensQuery.data?.data?.[0].id);
			sessionTokensQuery.refetch();
			toggleOpenDeleteConfirmationModal();
		}
	};

	if (!sessionTokensQuery.isFetched || !sessionQuery.data) {
		return undefined;
	}

	return (
		<Stack>
			<ConfirmationModal
				open={isOpenDeleteConfirmationModal}
				action={EConfirmationModalAction.DELETE}
				handleModaleClose={toggleOpenDeleteConfirmationModal}
				handleAction={handleExternalLinkDeleteClick}
				maxWidth={"sm"}
			/>
			<PaperTitle
				title={{
					icon: { variant: "h5", prefix: "fal", name: "clipboard-list", color: "primary.light" },
					children: <Typography variant="h5">{t(`${component}.title`)}</Typography>,
				}}
			>
				<Grid container spacing={2}>
					<GridLabel label={t(`${component}.displayer.code`)}>
						<Typography>{sessionQuery.data.code ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.organizedBy`)}>
						<Typography>{sessionQuery.data.organizedBy ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.title`)}>
						<Typography>{sessionQuery.data.title ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.description`)}>
						<Typography>{sessionQuery.data.description ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.isPrivate`)}>
						<Checkbox checked={sessionQuery.data.isPrivate === true} isDisabled />
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.externalLink`)} gridSize={{ xs: 12 }}>
						<Stack direction="row" alignItems={"center"}>
							{externalLink ? <Link to={externalLink}>{externalLink}</Link> : <br />}
							{externalLink && <Button onClick={toggleOpenDeleteConfirmationModal} {...getButton({ type: "delete" })} isIcon variant="text" />}
						</Stack>
					</GridLabel>
				</Grid>
			</PaperTitle>
		</Stack>
	);
};
