import ConfigurationsApp from "@application/Configurations";
import HelpersApp from "@application/helpers";
import {
	EnumParticipantStatusInvitation,
	ErrorAPI,
	queryIncludeCustomFields,
	Services,
	type TypeApiResponsePost,
	useContextModule,
} from "@key4-front-library/core";
import type { DtoChairGetQuality, DtoSessionGetChair } from "@key4-front-library/core/Dto";
import type { DtoChairWrite } from "@key4-front-library/core/Dto/ChairDto";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";

const useEntity = () => {
	const { client, event } = useContextModule();

	const create = async (useFormData: TypeUseFormListForms, sessionId: string): Promise<TypeApiResponsePost | ErrorAPI> => {
		const chair: DtoChairWrite = ConfigurationsApp.ChairConfiguration.defaultModel;
		HelpersApp.ChairHelper.mapUseFormToDtoChairWrite(useFormData, chair);

		let participantId: string | undefined;

		// If the selected chair is not a participant but only a contact, we need to create a participant
		try {
			participantId = (await Services.Events.Registration.ParticipantsService.get(client.id, event.id, chair.participantId)).id;
		} catch (error) {
			participantId = undefined;
		}

		let newParticipant: TypeApiResponsePost = { id: "" };

		if (!participantId) {
			const contact = await Services.Operations.Registration.ContactsService.get(client.id, event.communityId ?? event.id, chair.participantId);

			newParticipant = await Services.Events.Registration.ParticipantsService.post(client.id, event.id, {
				email: contact.data.email,
				firstname: contact.data.firstname,
				lastname: contact.data.lastname,
				customFieldValues: contact.data.customFieldValues,
				invitationStatus: EnumParticipantStatusInvitation.UNSENT,
			});
		}

		return await Services.Events.Programme.SessionsService.postChair(
			client.id,
			event.id,
			sessionId,
			participantId ? chair.participantId : newParticipant.id,
			chair,
		);
	};

	const readListChairQualities = async (): Promise<Array<DtoChairGetQuality>> => {
		return await Services.Events.Programme.ChairsService.getListQualities(client.id, event.id);
	};

	const readChair = async (sessionId: string, chairId: string): Promise<DtoSessionGetChair | ErrorAPI> => {
		try {
			return await Services.Events.Programme.SessionsService.getChair(client.id, event.id, sessionId, chairId, queryIncludeCustomFields(true));
		} catch (e: any) {
			return Promise.reject(new ErrorAPI(e.status, e.message));
		}
	};

	const update = async (sessionId: string, useFormData: TypeUseFormListForms, chairId: string): Promise<boolean> => {
		try {
			const chair: DtoChairWrite = ConfigurationsApp.ChairConfiguration.defaultModel;
			HelpersApp.ChairHelper.mapUseFormToDtoChairWrite(useFormData, chair);
			await Services.Events.Programme.SessionsService.putChair(client.id, event.id, sessionId, chairId, chair);
			return true;
		} catch {
			return false;
		}
	};

	return { readListChairQualities, create, readChair, update };
};

const ChairController = {
	useEntity,
};

export default ChairController;
