import {
	type LiteTagRead,
	type SessionRead,
	type SessionReadV1,
	type SessionWrite,
	type TagRead,
	type TokenRead,
	getSession,
	getSessionTags,
	getSessionTokens,
	getSessionV1,
	getSessionsTags,
	postSession,
	putSession,
} from "@api";
import {
	API_VERSION_QUERY_PARAM,
	type Paginated,
	QUERY_KEYS_PROGRAMME,
	type UseMutationArgs,
	type UseQueryArgs,
	overrideQueryParams,
	overrideVersion2,
} from "@key4-front-library/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const useSession = (clientId: string, eventId: string, sessionId: string, args: UseQueryArgs<SessionRead> = {}): UseQueryResult<SessionRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", clientId, eventId, sessionId, queryStrings],
		queryFn: ({ signal }) =>
			getSession({
				clientId,
				eventId,
				sessionId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export const useSessionV1 = (clientId: string, eventId: string, sessionId: string, args: UseQueryArgs<SessionReadV1> = {}): UseQueryResult<SessionReadV1> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", "V1", clientId, eventId, sessionId, queryStrings],
		queryFn: ({ signal }) =>
			getSessionV1({
				clientId,
				eventId,
				sessionId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "1.0" }]),
				signal,
			}),
		...others,
	});
};

export const useSessionsTags = (clientId: string, eventId: string, args: UseQueryArgs<Paginated<TagRead>> = {}): UseQueryResult<Paginated<TagRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", "Tags", clientId, eventId, queryStringOverride],
		queryFn: ({ signal }) => getSessionsTags({ clientId, eventId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export const useSessionTags = (
	clientId: string,
	eventId: string,
	sessionId: string,
	args: UseQueryArgs<Paginated<LiteTagRead>> = {},
): UseQueryResult<Paginated<LiteTagRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", "Tags", clientId, eventId, sessionId, queryStringOverride],
		queryFn: ({ signal }) => getSessionTags({ clientId, eventId, sessionId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};

export interface SessionCreateRequest {
	clientId: string;
	eventId: string;
	body: SessionWrite;
	signal?: AbortSignal;
}

export const useSessionCreate = (args: UseMutationArgs<SessionCreateRequest, string>): UseMutationResult<string, Error, SessionCreateRequest> => {
	return useMutation<string, Error, SessionCreateRequest>({
		mutationFn: (request: SessionCreateRequest) => postSession(request),
		...args,
	});
};

export interface SessionUpdateRequest {
	clientId: string;
	eventId: string;
	sessionId: string;
	body: SessionWrite;
	signal?: AbortSignal;
}

export const useSessionUpdate = (args: UseMutationArgs<SessionUpdateRequest>): UseMutationResult<boolean, Error, SessionUpdateRequest> => {
	return useMutation({
		mutationFn: (request: SessionUpdateRequest) => putSession(request),
		...args,
	});
};

export const useSessionTokens = (
	clientId: string,
	eventId: string,
	sessionId: string,
	args: UseQueryArgs<Paginated<TokenRead>> = {},
): UseQueryResult<Paginated<TokenRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	const queryStringOverride = overrideVersion2(queryStrings);

	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", "Tokens", clientId, eventId, sessionId, queryStringOverride],
		queryFn: ({ signal }) => getSessionTokens({ clientId, eventId, sessionId, queryStrings: queryStringOverride, signal }),
		...others,
	});
};
