import type { PresentationRead, SessionReadV1, SpeakerRead, TagTypeWithTagRead } from "@api";
import type { SchedulerCardPopoverProps, TimeSlotSectionProps } from "@components";
import { getActivityStatus, getSessionStatusTag } from "@configurations";
import { getLink } from "@helpers";
import {
	Typography,
	getDateHoursAndDurationString,
	getHoursAndDuration,
	getHoursAndDurationString,
	getPersonTitle,
	isoStringDateToDatePaperProps,
	stringISOToDateTime,
} from "@key4-front-library/core";
import type { Theme } from "@mui/material";
import { DateTime } from "luxon";

interface SessionToSessionCardPopoverArgs {
	keys: Array<string>;
	session: SessionReadV1;
	presentations: Array<PresentationRead>;
	speakers?: Array<SpeakerRead>;
	theme: Theme;
}

export const sessionToSchedulerCardPopover = (args: SessionToSessionCardPopoverArgs): SchedulerCardPopoverProps => {
	const { keys, presentations, session, speakers, theme } = args;
	const shouldDisplayDatePaper = session.startDate === session.endDate;
	return {
		to: getLink("sessionDetailsGeneral", keys),
		datePaper: isoStringDateToDatePaperProps(session.startDate),
		shouldDisplayDatePaper,
		hours: getHoursString(shouldDisplayDatePaper, session),
		status: getSessionStatusTag(theme)[session.status],
		hasClashes: session.clashesCount > 0,
		title: session.title,
		description: session.description,
		code: session.code,
		tags: tagTypeWithTagReadsToTagPropsList(session.tagTypes),
		timeSlotSections: presentationReadsToTimeSlotSection(theme, presentations, speakers),
	};
};

const tagTypeWithTagReadsToTagPropsList = (tags: Array<TagTypeWithTagRead> | undefined) =>
	tags
		?.flatMap((tagType) =>
			tagType.tags?.map((tag) => ({
				backgroundColor: tag.backgroundColor,
				borderColor: tag.borderColor,
				fontColor: tag.fontColor,
				children: <Typography variant="chip">{tag.label}</Typography>,
			})),
		)
		.filter((tag) => !!tag)
		.slice(0, 5);

const presentationReadsToTimeSlotSection = (theme: Theme, presentations: Array<PresentationRead>, speakers?: Array<SpeakerRead>): Array<TimeSlotSectionProps> =>
	presentations.map((presentation) => {
		const hoursAndDuration =
			presentation.startDate && presentation.endDate
				? getHoursAndDuration(stringISOToDateTime(presentation.startDate), stringISOToDateTime(presentation.endDate))
				: undefined;
		return {
			hours: hoursAndDuration?.hours,
			duration: hoursAndDuration?.duration,
			code: presentation.code,
			body: presentation.title,
			bulletTexts: speakers
				?.filter((speaker) => speaker.presentationId === presentation.id)
				.map((speaker) => ({
					bulletColor: getActivityStatus(theme)[speaker.invitationStatus].color,
					text: getPersonTitle(speaker),
					typography: { color: "textSecondary" },
				})),
		};
	});

const getHoursString = (isDatePaper: boolean, session: SessionReadV1) => {
	if (isDatePaper) {
		return session.startHour && session.endHour
			? getHoursAndDurationString(DateTime.fromFormat(session.startHour, "HH:mm:ss"), DateTime.fromFormat(session.endHour, "HH:mm:ss"))
			: undefined;
	}

	return session.startDate && session.startHour
		? getDateHoursAndDurationString(
				DateTime.fromFormat(`${session.startDate} ${session.startHour}`, "yyyy-MM-dd HH:mm:ss"),
				DateTime.fromFormat(`${session.endDate} ${session.endHour}`, "yyyy-MM-dd HH:mm:ss"),
			)
		: undefined;
};
