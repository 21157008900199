import { t } from "i18next";
import { cloneDeep } from "lodash";
import { useSnackbar } from "notistack";
import { type BaseSyntheticEvent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import ControllersApp from "@application/Controllers/ControllersApp";
import HelpersApp from "@application/helpers";
import HooksApp from "@application/hooks";
import { ButtonCancel, ButtonSave, DialogOld, FormTabsOld, isErrorApi, PersonHeader } from "@key4-front-library/core";
import DialogTitle from "@key4-front-library/core/Bo/Components/DialogTitle";
import { Skeleton, Stack } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { HttpStatusCode } from "axios";

export type PropsContainerSpeakerModal = {
	activeTabKey?: string;
	callbackSubmit?: () => void;
	changeIsOpen: (newIsOpen: boolean) => void;
	speakerId?: string;
	isOpen: boolean;
	presentationId: string;
	sessionId: string;
};

const ContainerSpeakerModal = (props: PropsContainerSpeakerModal) => {
	const { activeTabKey, callbackSubmit, changeIsOpen, speakerId, presentationId, isOpen, sessionId } = props;
	const { enqueueSnackbar } = useSnackbar();

	const [speaker, setSpeaker] = useState<any>(null);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

	const { readWholeForm } = HooksApp.SpeakerHook.useEntity();
	const { create, readSpeaker, update } = ControllersApp.SpeakerController.useEntity();
	const { data, isFetching, refetch, isFetchedAfterMount } = readWholeForm(sessionId, speaker);

	const formMethods = useForm<TypeUseFormListForms>({
		mode: "onSubmit",
		resolver: HelpersApp.SpeakerHelper.getResolver(data ? cloneDeep(data.useFormData) : undefined, speakerId),
	});

	const { handleSubmit: onSubmit, reset } = formMethods;

	useEffect(() => {
		if (isOpen && speakerId) {
			getSpeaker(speakerId);
		} else {
			setSpeaker(null);
			refetch();
		}
	}, [isOpen]);

	useEffect(() => {
		refetch();
	}, [speaker]);

	const getSpeaker = async (speakerId: string) => {
		const speaker = await readSpeaker(sessionId, presentationId, speakerId);
		setSpeaker(speaker);
		refetch();
		return speaker;
	};

	const displaySnackbar = (isError: boolean, customMessage?: string | null) => {
		enqueueSnackbar(customMessage ?? t("errors.generic"), {
			variant: isError ? "error" : "success",
		});
	};

	const handleSubmit = async (useFormData: TypeUseFormListForms) => {
		// Create a new speaker
		if (!speakerId) {
			try {
				await create(useFormData, sessionId, presentationId, true);
			} catch (error: unknown) {
				let errorMessage: string | null = null;
				if (isErrorApi(error)) {
					errorMessage = error.status === HttpStatusCode.UnprocessableEntity ? t("old.programme.sessionDetails.speakers.form.add.speakerAlreadyExist") : null;
				}
				displaySnackbar(true, errorMessage);
				return;
			}

			displaySnackbar(false, t("old.programme.sessionDetails.speakers.form.add.success"));
			changeIsOpen(false);
			callbackSubmit?.();
			setIsLoadingSubmit(false);
			return;
		}

		if (await update(sessionId, presentationId, speakerId, useFormData)) {
			displaySnackbar(false, t("old.programme.sessionDetails.speakers.form.edit.success"));
			changeIsOpen(false);
			await getSpeaker(speakerId).then(() => callbackSubmit?.());
			setIsLoadingSubmit(false);
			return;
		}

		displaySnackbar(true);
		setIsLoadingSubmit(false);
	};

	const handleInvalid = (_error: any) => {
		setIsLoadingSubmit(false);
		enqueueSnackbar(t("old.programme.sessionDetails.speakers.form.errors.invalidForm"), {
			variant: "error",
		});
	};

	useEffect(() => {
		if (!isFetching && data) {
			reset(data.useFormData);
		}
	}, [isFetching]);

	return (
		<DialogOld isOpen={isOpen}>
			<>
				<DialogTitle
					title={t(`old.programme.sessionDetails.speakers.form.${speakerId ? "edit" : "add"}.title`)}
					onCloseClick={() => {
						changeIsOpen(false);
					}}
				/>
				<DialogContent>
					{speakerId && (
						<PersonHeader
							data={speaker}
							stackProps={{
								pb: 2,
								spacing: 1,
							}}
						/>
					)}

					{data && isFetchedAfterMount ? (
						<FormProvider {...formMethods}>
							<FormTabsOld activeTabKey={activeTabKey} {...data.componentData} />
						</FormProvider>
					) : (
						<Stack spacing={2}>
							<Skeleton sx={{ height: 40 }} animation="wave" variant="rectangular" />
							<Skeleton sx={{ height: 450 }} animation="wave" variant="rectangular" />
						</Stack>
					)}
				</DialogContent>
				<DialogActions>
					<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
						<ButtonCancel
							onClick={() => {
								changeIsOpen(false);
							}}
						/>
						<ButtonSave
							isLoading={isLoadingSubmit}
							onClick={(e: BaseSyntheticEvent<object, any, any> | undefined) => {
								setIsLoadingSubmit(true);
								onSubmit(handleSubmit, handleInvalid)(e);
							}}
						/>
					</Stack>
				</DialogActions>
			</>
		</DialogOld>
	);
};

export default ContainerSpeakerModal;
