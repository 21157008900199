import { useSession } from "@api";
import { Grid, GridLabel, PaperTitle, Stack, Typography, useContextModule } from "@key4-front-library/core";
import { t } from "i18next";
import { useParams } from "react-router-dom";

export const RoomSectionSession = (): React.ReactNode => {
	const component = "roomSectionSession";

	const { sessionId = "" } = useParams();
	const { client, event } = useContextModule();

	const sessionQuery = useSession(client.id, event.id, sessionId);

	if (!sessionQuery.data) {
		return undefined;
	}

	return (
		<Stack>
			<PaperTitle
				title={{
					icon: { variant: "h5", prefix: "fal", name: "screen-users", color: "primary.light" },
					children: <Typography variant="h5">{t(`${component}.title`)}</Typography>,
				}}
			>
				<Grid container spacing={2}>
					<GridLabel label={t(`${component}.displayer.room`)}>
						<Typography>{sessionQuery.data.room?.name ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.capacity`)}>
						<Typography>{sessionQuery.data.room?.capacity ?? <br />}</Typography>
					</GridLabel>
					<GridLabel label={t(`${component}.displayer.expectedAudience`)}>
						<Typography>{sessionQuery.data.expectedAudience ?? <br />}</Typography>
					</GridLabel>
				</Grid>
			</PaperTitle>
		</Stack>
	);
};
