import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import ConfigurationsApp from "@application/Configurations";
import { GetMenuActionsConfigurations } from "@application/Configurations/menu-actions.configuration";
import ContainerExternalSessionLinkModal from "@application/Containers/ContainerExternalSessionLinkModal";
import SessionController from "@application/Controllers/SessionController";
import OrganizedByView from "@application/components/sessions/OrganizedByView";
import PublishView from "@application/components/sessions/PublishView";
import SelectStatus from "@application/components/sessions/SelectStatus";
import { EnumSessionFacultyClashesEntityType } from "@application/enum/Clashes.enum";
import { MenuActionsReference } from "@application/enum/MenuActionsReference.enum";
import { EConfirmationModalAction } from "@domain/interfaces/form.interface";
import Card from "@infrastructure/components/interface/card/Card";
import Loading from "@infrastructure/components/interface/loading/Loading";
import K4Tags from "@infrastructure/components/interface/tags/K4Tags";
import { isGuid } from "@infrastructure/services/api/guid";
import {
	ButtonsTitle,
	ConfirmationModal,
	Culture,
	Cultures,
	type DtoFaculty,
	ESieveOperator,
	ErrorAPI,
	Icon,
	QUERY_KEYS_PROGRAMME,
	Services,
	type TabProps,
	TabsLayoutWithSidePanel,
	Typography,
	getButton,
	getLocalDateTime,
	queryFilters,
	queryStringPagination,
	sessionStatus,
	sortToQueryString,
	statusSessionObject,
	useAuthenticationContext,
	useContextModule,
	useTabsRoute,
	useToggle,
} from "@key4-front-library/core";
import { Box, Grid, Stack } from "@mui/material";

import { useAnomalies } from "@hooks/Anomaly";
import { useClashes } from "@hooks/Clash";
import { useSessionView } from "@hooks/Session";

import { useSessionTokens } from "@api";
import { EditPresentationsSchedule, type SessionEditSchedule } from "@application/Dialogs";
import { type InjectSessionData, SessionModal } from "@components";
import type { ISessionView } from "@domain/interfaces/session.interface";
import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { tokenReadToExternalLink } from "@mappers";
import { getSessionsDetailsTabs } from "@pages";
import { useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";

export const SessionDetails = (): React.ReactNode => {
	const navigate = useNavigate();
	const { sessionId = "" } = useParams();

	const { client, event } = useContextModule();
	const { user } = useAuthenticationContext();
	const { enqueueSnackbar } = useSnackbar();

	const [session, setSession] = useState<ISessionView>();
	const [tabs, setTabs] = useState<Array<TabProps>>();
	const [numberOfPresentations, setNumberOfPresentations] = useState<number>(0);
	const [chairs, setChairs] = useState<Array<DtoFaculty>>([]);
	const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
	const [error, setError] = useState<ErrorAPI | undefined>();
	const [culture, setCulture] = useState<string>(Culture[0].culture);
	const [isOpenExternalLinkModal, setIsOpenExternalLinkModal] = useState(false);
	const [selectedSessionEditSchedule, setSelectedSessionEditSchedule] = useState<SessionEditSchedule | undefined>(undefined);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [activeClashesCounter, setActiveClashesCounter] = useState<number>(0);
	const [activeAnomaliesCounter, setActiveAnomaliesCounter] = useState<number>(0);
	const [externalLink, setExternalLink] = useState<string>();
	const [injectSessionData, setInjectSessionData] = useState<InjectSessionData>();

	const { isToggle: isEditPresentationsScheduleOpen, toggle: toggleIsEditPresentationsScheduleOpen } = useToggle(false);
	const { isToggle: isOpenSessionModal, toggle: toggleOpenSessionModal } = useToggle();
	const sessionTokenQuery = useSessionTokens(client.id, event.id, sessionId, { queryStrings: sortToQueryString([{ field: "expireAt", sort: "desc" }]) });
	const sessionViewQuery = useSessionView(client.id, event.id, sessionId);
	const queryClient = useQueryClient();

	useEffect(() => {
		setTabs(getSessionsDetailsTabs(numberOfPresentations, chairs.length, activeClashesCounter, activeAnomaliesCounter));
	}, [numberOfPresentations, chairs, activeClashesCounter, activeAnomaliesCounter]);

	useEffect(() => {
		if (sessionTokenQuery.data?.data?.[0]) {
			setExternalLink(tokenReadToExternalLink(sessionTokenQuery.data.data[0], event.domain));
		}
	}, [sessionTokenQuery.data?.data, event.domain]);

	const defaultCurrentSession = {
		...ConfigurationsApp.SessionConfiguration.defaultModel2,
	};

	if (sessionId) defaultCurrentSession.id = sessionId;

	const confirmationModalProps = useRef({
		action: EConfirmationModalAction.DELETE,
		handleAction: () => {},
	});

	useEffect(() => {
		const codeCulture = Cultures[user.locale].locale;
		setCulture(codeCulture);
	}, [t]);

	const actionsConfigurations = GetMenuActionsConfigurations();

	const cloneConfig = actionsConfigurations.find((x) => x.reference === MenuActionsReference.CLONE);
	const deleteConfig = actionsConfigurations.find((x) => x.reference === MenuActionsReference.DELETE);

	const initComponent = useCallback(() => {
		if (!sessionId) return;

		if (!isGuid(sessionId)) {
			setError(new ErrorAPI(HttpStatusCode.NotFound, "session not found"));

			return;
		}

		const fetchData = async () => {
			return {
				_session: await SessionController.getSessionDetails(client.id, event.id, sessionId, culture),
				_chairs: await SessionController.getChairs({
					clientId: client.id,
					sessionId,
					eventId: event.id,
					sorts: ["order"],
				}),
			};
		};

		fetchData().then((results) => {
			const { _session, _chairs } = results;
			if (_session instanceof ErrorAPI) {
				setError(_session);
				return;
			}
			if (_chairs instanceof ErrorAPI) {
				setError(_chairs);
				return;
			}
			setSession(_session);
			setChairs(_chairs);
			setIsApiLoading(false);
		});
	}, [sessionId, event, culture]);

	const clashesCounterQuery = useClashes({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		queryStrings: [
			...queryFilters(
				`ignoredDate${ESieveOperator.EQUALS}null${ESieveOperator.AND}entityType${ESieveOperator.EQUALS + EnumSessionFacultyClashesEntityType.ALL}`,
			),
			...queryStringPagination({
				page: 0,
				pageSize: 1,
			}),
		],
	});

	const anomaliesCounterQuery = useAnomalies({
		clientId: client.id,
		eventId: event.id,
		sessionId,
		queryStrings: [
			...queryFilters(`onlyActive${ESieveOperator.EQUALS}true`),
			...queryStringPagination({
				page: 0,
				pageSize: 1,
			}),
		],
	});

	useEffect(() => {
		setActiveAnomaliesCounter(anomaliesCounterQuery.data?.pagination.totalCount ?? 0);
	}, [anomaliesCounterQuery.data]);

	useEffect(() => {
		setActiveClashesCounter(clashesCounterQuery.data?.pagination.totalCount ?? 0);
	}, [clashesCounterQuery.data]);

	const initBadgesCountersTabs = useCallback(() => {
		if (!sessionId) return;

		if (!isGuid(sessionId)) {
			setError(new ErrorAPI(HttpStatusCode.NotFound, "session not found"));
			return;
		}

		const fetchData = async () => {
			return {
				_unactiveClashes: await Services.Events.Programme.SessionsService.getListPaginedClashes(client.id, event.id, sessionId, [
					...queryFilters(
						`ignoredDate${ESieveOperator.NOT_EQUALS}null${ESieveOperator.AND}entityType${ESieveOperator.EQUALS + EnumSessionFacultyClashesEntityType.ALL}`,
					),
					...queryStringPagination({
						page: 0,
						pageSize: 100,
					}),
				]),
				_unactiveAnomalies: await Services.Events.Programme.SessionsService.getListPaginedAnomalies(client.id, event.id, sessionId, [
					...queryFilters(`onlyActive${ESieveOperator.EQUALS}false`),
					...queryStringPagination({
						page: 0,
						pageSize: 100,
					}),
				]),
				_presentations: await Services.Events.Programme.SessionsService.getListPresentations(client.id, event.id, sessionId),
			};
		};

		fetchData().then((results) => {
			const { _unactiveClashes, _unactiveAnomalies, _presentations } = results;
			if (_unactiveClashes instanceof ErrorAPI) {
				setError(_unactiveClashes);
				return;
			}
			if (_unactiveAnomalies instanceof ErrorAPI) {
				setError(_unactiveAnomalies);
				return;
			}
			if (_presentations instanceof ErrorAPI) {
				setError(_presentations);
				return;
			}

			setNumberOfPresentations(_presentations.length);
		});
	}, [sessionId, event]);

	useEffect(() => {
		initComponent();
		initBadgesCountersTabs();
	}, [event, initBadgesCountersTabs, initComponent]);

	const handleModaleClose = (): void => {
		setOpenConfirmationModal(false);
	};

	const titleSettingsButtons = [
		{
			label: t("old.form.buttons.clone"),
			icon: { iconName: cloneConfig?.icon! },
			handleClick: () => {
				confirmationModalProps.current = {
					action: EConfirmationModalAction.CLONE,
					handleAction: () => {
						session && handleSessionClone(session.sessionUseForm.id ?? "");
					},
				};
				setOpenConfirmationModal(true);
			},
		},
		{
			label: t("old.form.buttons.delete"),
			icon: { iconName: deleteConfig?.icon! },
			handleClick: () => {
				confirmationModalProps.current = {
					action: EConfirmationModalAction.DELETE,
					handleAction: () => {
						session && handleDeleteSession(session.sessionUseForm.id ?? "");
					},
				};
				setOpenConfirmationModal(true);
			},
		},
		{
			label: t("old.form.buttons.externalEditingLink"),
			icon: { iconName: "arrow-up-right-from-square" as IconName },
			handleClick: () => {
				setIsOpenExternalLinkModal(true);
			},
			disabled: !!externalLink,
		},
	];

	const refreshSession = async () => {
		sessionViewQuery.refetch();
		if (!sessionId) return;
		const fetchData = async () => {
			return {
				_session: await SessionController.getSessionDetails(client.id, event.id, sessionId, culture),
			};
		};
		fetchData().then((results) => {
			const { _session } = results;
			if (_session instanceof ErrorAPI) {
				setError(_session);
				return;
			}
			setSession(_session);
		});
	};

	const handleOpenModaleSession = (injectSessionData?: InjectSessionData) => {
		setInjectSessionData(injectSessionData);
		toggleOpenSessionModal();
	};

	const handleSessionCallbackSubmit = (sessionEditSchedule?: SessionEditSchedule) => {
		void queryClient.invalidateQueries({
			queryKey: [...QUERY_KEYS_PROGRAMME.sessions, "Get", client.id, event.id, sessionId],
		});
		if (sessionEditSchedule) {
			setSelectedSessionEditSchedule(sessionEditSchedule);
			toggleIsEditPresentationsScheduleOpen();
		}
	};

	const handleDeleteSession = async (_id: string) => {
		if (_id) {
			try {
				await SessionController.deleteSession(client.id, event.id, _id);
				enqueueSnackbar(t("old.programme.sessions.create_edit_modale.snackbar.deleted"));
				navigate("..");
			} catch (_error: any) {
				enqueueSnackbar(_error.message, { variant: "error" });
			} finally {
				handleModaleClose();
			}
		}
	};

	const handleSessionClone = async (sessionId: string) => {
		await SessionController.cloneSession({
			clientId: client.id,
			eventId: event.id,
			sessionId,
		})
			.then(() => {
				enqueueSnackbar(<Box>{t("old.programme.sessions.create_edit_modale.snackbar.cloned")}</Box>, { variant: "success" });
			})
			.catch((error) => enqueueSnackbar(error.message, { variant: "error" }))
			.finally(() => {
				handleModaleClose();
			});
	};

	const { active, isValidPath } = useTabsRoute(tabs?.map((tab) => tab.to) ?? []);

	if (!tabs || tabs?.length === 0) {
		return undefined;
	}

	const tabActive = isValidPath ? tabs[active] : tabs[0];

	if (isApiLoading || !session) return <Loading />;

	if (error) throw error;

	if (session && sessionId) {
		return (
			<Stack>
				<ConfirmationModal
					open={openConfirmationModal}
					action={confirmationModalProps.current.action}
					handleModaleClose={handleModaleClose}
					handleAction={confirmationModalProps.current.handleAction}
					maxWidth={"sm"}
				/>
				{isOpenSessionModal && (
					<SessionModal
						isOpened={isOpenSessionModal}
						onClose={toggleOpenSessionModal}
						sessionId={sessionId}
						onCallbackSubmit={handleSessionCallbackSubmit}
						injectSessionData={injectSessionData}
					/>
				)}
				<ContainerExternalSessionLinkModal
					isOpen={isOpenExternalLinkModal}
					changeIsOpen={setIsOpenExternalLinkModal}
					sessionId={sessionId}
					refreshSession={sessionTokenQuery.refetch}
				/>
				<EditPresentationsSchedule
					sessionEditSchedule={selectedSessionEditSchedule}
					isOpen={isEditPresentationsScheduleOpen}
					onClose={() => {
						toggleIsEditPresentationsScheduleOpen();
					}}
				/>
				<TabsLayoutWithSidePanel
					title={{
						children: (
							<Stack>
								<Stack direction="row" spacing={1} alignItems={"center"}>
									<Typography variant="h4">{session.sessionDisplay.title}</Typography>
									<Icon variant="h5" prefix="fas" name={"circle"} color={statusSessionObject[session.sessionDisplay.status].bgColor} />
								</Stack>
								<Typography variant={"h5"} fontWeight={"light"} color={"text.disabled"}>
									{session.sessionDisplay.code}
								</Typography>
							</Stack>
						),
						buttonGroup: {
							buttons: [
								{
									onClick: () => handleOpenModaleSession(),
									...getButton({ type: "update" }),
									children: t("old.form.buttons.edit"),
								},
							],
						},
						dropDownMenu: <ButtonsTitle buttons={[]} settingsButtons={titleSettingsButtons} />,
					}}
					active={active}
					tabs={tabs}
					sidePanel={
						<Stack my={2} spacing={2}>
							<Card isHeader={false}>
								<Grid spacing=".3rem" container>
									<Grid item xs={12}>
										<PublishView
											data={session.publish}
											handleClick={() => {
												handleOpenModaleSession({ publicationDate: getLocalDateTime() });
											}}
										/>
									</Grid>
									<Grid item xs={12} mt=".5rem">
										<SelectStatus
											session={session.sessionDisplay}
											clientId={client.id}
											eventId={event.id}
											status={sessionStatus}
											defaultValue={session.sessionDisplay.status}
											refreshSession={refreshSession}
										/>
									</Grid>
								</Grid>
							</Card>
							{session.tags && (
								<Card title={t("old.programme.sessionDetails.tags.label")}>
									<Grid my="1rem" spacing=".3rem" container>
										<Grid item xs={12}>
											<K4Tags tags={session.tags} />
										</Grid>
									</Grid>
								</Card>
							)}
							{session.sessionDisplay.organizedBy && (
								<Card title={t("old.programme.sessionDetails.organizedBy.label")}>
									<Grid my="1rem" spacing=".3rem" container>
										<Grid item xs={12}>
											<OrganizedByView name={session.sessionDisplay.organizedBy} />
										</Grid>
									</Grid>
								</Card>
							)}
						</Stack>
					}
				>
					{tabActive && <Outlet />}
				</TabsLayoutWithSidePanel>
			</Stack>
		);
	}
};
