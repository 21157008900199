import type { SessionTemplateRead } from "@api";
import type { SpeakerModalForm } from "@components";
import { type CustomFieldForm, customFieldsZod } from "@key4-front-library/core";
import { t } from "i18next";
import type { UseFormReturn } from "react-hook-form";
import { z } from "zod";

export const speakerDefaultValues: CustomFieldForm<SpeakerModalForm> = {
	participantOrContact: null,
	qualityId: "",
	invitationStatus: "unsent",
	replacementEmail: "",
	replacementFirstname: "",
	replacementLastname: "",
	replacementComment: "",
	customFields: {},
};

const baseSchema = z.object({
	participantOrContact: z.any(),
	qualityId: z.string().optional(),
	invitationStatus: z.string(),
	replacementEmail: z.string().optional(),
	replacementFirstname: z.string().optional(),
	replacementLastname: z.string().optional(),
	replacementComment: z.string().optional(),
	customFields: customFieldsZod,
});

export const speakerSchema = (id?: string) =>
	baseSchema.superRefine((data, ctx) => {
		if (data.participantOrContact === null && !id) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: t("form.validation.required"),
				path: ["participantOrContact"],
			});
		}
	});

export const overrideSpeakerWithSessionTemplate = (
	form: UseFormReturn<CustomFieldForm<SpeakerModalForm>, undefined, undefined>,
	sessionTemplate?: SessionTemplateRead,
) => {
	if (sessionTemplate?.speakerInvitationStatus) {
		form.setValue("invitationStatus", sessionTemplate.speakerInvitationStatus);
	}
};
