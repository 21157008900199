import type { SessionTemplateRead } from "@api";
import type { SessionModalForm } from "@components";
import { TIME_WITH_SECONDS_FORMAT } from "@key4-front-library/core";
import { durationISOToString } from "@mappers";
import { DateTime } from "luxon";
import type { UseFormReturn } from "react-hook-form";

export const overrideWithSessionTemplate = (form: UseFormReturn<SessionModalForm, undefined, undefined>, sessionTemplate: SessionTemplateRead) => {
	const startHour = sessionTemplate.startHour ? DateTime.fromFormat(sessionTemplate.startHour, TIME_WITH_SECONDS_FORMAT) : undefined;
	const endHour = sessionTemplate.endHour ? DateTime.fromFormat(sessionTemplate.endHour, TIME_WITH_SECONDS_FORMAT) : undefined;

	if (startHour && endHour) {
		form.setValue("startTime", startHour);
		form.setValue("endTime", endHour);
	}

	form.setValue("status", sessionTemplate.status);
	form.setValue("duration", durationISOToString(sessionTemplate.duration) ?? "");
	form.setValue("isPrivate", sessionTemplate.isPrivate);
	form.setValue("shouldApplySessionTemplate", true);
};
