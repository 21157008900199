import { useSession, useSessionCreate, useSessionTags, useSessionUpdate } from "@api";
import { SessionForm, type SessionModalForm, sessionDefaultValues, sessionReadToSessionForm, sessionSchema } from "@components";
import { sessionFormToSessionWrite } from "@components";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Button,
	type CustomFieldForm,
	DialogAdvanced,
	FormTabsCustomField,
	Link,
	Stack,
	Typography,
	copyToClipboard,
	getDialogAdvanced,
	metaEnv,
	useContextModule,
	useSnackbar,
} from "@key4-front-library/core";
import { getPath } from "@routes";
import { t } from "i18next";
import type { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { type FieldErrors, FormProvider, useForm } from "react-hook-form";

export interface InjectSessionData {
	roomId?: string | null;
	publicationDate?: DateTime | null;
	startDate?: DateTime | null;
	endDate?: DateTime | null;
}
export interface SessionModalProps {
	isOpened: boolean;
	onClose: () => void;
	onCallbackSubmit?: () => void;
	sessionId?: string;
	injectSessionData?: InjectSessionData;
}

export const SessionModal = (props: SessionModalProps) => {
	const component = "sessionModal";
	const { isOpened, onClose, sessionId, onCallbackSubmit, injectSessionData } = props;

	const [isLoaded, setIsLoaded] = useState(false);

	const { client, event } = useContextModule();
	const snackbar = useSnackbar();
	const sessionQuery = useSession(client.id, event.id, sessionId ?? "", {
		enabled: !!sessionId,
		queryStrings: [{ key: "includeCustomFields", value: "true" }],
	});
	const sessionTagsQuery = useSessionTags(client.id, event.id, sessionId ?? "", { enabled: !!sessionId });

	const form = useForm<CustomFieldForm<SessionModalForm>>({
		mode: "all",
		reValidateMode: "onChange",
		criteriaMode: "all",
		defaultValues: sessionDefaultValues(injectSessionData),
		resolver: zodResolver(sessionSchema),
	});

	useEffect(() => {
		if (sessionQuery.isFetched && sessionQuery.data && sessionTagsQuery.isFetched && isLoaded) {
			form.reset({
				...sessionReadToSessionForm(sessionQuery.data, form.getValues("customFields"), sessionTagsQuery.data?.data),
				...injectSessionData,
			});
		}
	}, [
		form.reset,
		sessionQuery.isFetched,
		sessionQuery.data,
		sessionTagsQuery.data?.data,
		sessionTagsQuery.isFetched,
		form.getValues,
		injectSessionData,
		isLoaded,
	]);

	const handleSessionSubmit = (_: string | boolean): void => {
		snackbar.enqueue({ type: "success", translation: sessionId ? `${component}.edit` : `${component}.create` });
		onCallbackSubmit?.();
		onClose();
	};

	const sessionCreate = useSessionCreate({ onSuccess: handleSessionSubmit });
	const sessionEdit = useSessionUpdate({ onSuccess: handleSessionSubmit });

	const handleSubmit = useCallback(
		(data: CustomFieldForm<SessionModalForm>) => {
			if (sessionId) {
				sessionEdit.mutate({
					clientId: client.id,
					eventId: event.id,
					sessionId: sessionId,
					body: sessionFormToSessionWrite(data, sessionQuery.data),
				});
			} else {
				sessionCreate.mutate({
					clientId: client.id,
					eventId: event.id,
					body: sessionFormToSessionWrite(data),
				});
			}
		},
		[client.id, event.id, sessionCreate, sessionId, sessionEdit, sessionQuery.data],
	);

	const handleError = useCallback(
		(_errors: FieldErrors<CustomFieldForm<SessionModalForm>>) => {
			snackbar.enqueue({ type: "invalidForm", translation: t("form.validation") });
		},
		[snackbar.enqueue],
	);

	const handleIsLoaded = () => setIsLoaded(true);

	const handleSaveClick = (): void => {
		void form.handleSubmit(handleSubmit, handleError)();
	};

	const handleCopyClick = (sessionId: string): void => {
		copyToClipboard(`${metaEnv.myKey4.endPoint.programme}${getPath("sessionDetails", [client.key, event.key, sessionId])}`);
		snackbar.enqueue({ type: "copy" });
	};

	return (
		<DialogAdvanced
			open={isOpened}
			{...getDialogAdvanced({
				type: "save",
				translation: sessionId ? `${component}.edit` : `${component}.create`,
				options: {
					onCancelClick: onClose,
					onActionClick: handleSaveClick,
				},
			})}
			title={{
				component: "div",
				children: (
					<Stack direction="row" spacing={1} alignItems={"center"}>
						<Typography variant="h6">
							{sessionId ? t(`${component}.edit.advancedDialog.save.title`) : t(`${component}.create.advancedDialog.save.title`)}
						</Typography>
						{sessionId && (
							<Stack direction="row" spacing={1} alignItems={"center"}>
								<Typography variant="h6">{" - "}</Typography>
								<Link to={getPath("sessionDetails", [client.key, event.key, sessionId])}>
									<Typography component={"span"} variant="h6">
										{sessionQuery.data?.key}
									</Typography>
								</Link>
								<Button
									variant="text"
									onClick={() => {
										handleCopyClick(sessionId);
									}}
									isIcon
									startIcon={{ name: "link-simple" }}
								/>
							</Stack>
						)}
					</Stack>
				),
			}}
			fullWidth
		>
			<FormProvider {...form}>
				<FormTabsCustomField id={sessionId} onIsLoaded={handleIsLoaded} module="programme" scope="session" businessForm={<SessionForm id={sessionId} />} />
			</FormProvider>
		</DialogAdvanced>
	);
};
