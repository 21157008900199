import type { InvitationSettingsWrite } from "@key4-front-library/core";
import { z } from "zod";

export const invitationSettingsDefaultValues: InvitationSettingsWrite = {
	shouldDisplayConflictOfInterestQuestion: true,
	shouldDisplayConflictOfInterestDetailsQuestion: true,
};

export const invitationSettingsSchema = z.object({
	shouldDisplayConflictOfInterestQuestion: z.boolean(),
	shouldDisplayConflictOfInterestDetailsQuestion: z.boolean(),
});
