import type { ActivityInvitationStatus } from "@api";
import { getActivityStatus } from "@configurations";
import type { ActionItem, BulletTextProps } from "@key4-front-library/core";
import type { Theme } from "@mui/material";

export const getInvitationActivityStatusesOptions = (theme: Theme): Array<ActionItem<BulletTextProps>> => {
	const activityInvitationStatuses = getActivityStatus(theme);
	return Object.keys(activityInvitationStatuses).map((activityStatus) => {
		const status = activityInvitationStatuses[activityStatus as ActivityInvitationStatus];
		return {
			value: activityStatus,
			name: status.name,
			component: {
				bulletColor: status.color,
				text: status.name,
				typography: { variant: "body1" },
			},
		};
	});
};
