import { Badge, MAIN_TAB, type TabProps } from "@key4-front-library/core";
import { t } from "i18next";

export const getSessionsDetailsTabs = (presentationCounter: number, chairCounter: number, clashCounter: number, anomalyCounter: number): Array<TabProps> => [
	{
		label: t("sessionDetails.tab.general"),
		to: MAIN_TAB,
	},
	{
		label: t("sessionDetails.tab.chairs"),
		to: "chairs",
		iconPosition: "end",
		icon: <Badge showZero badgeContent={chairCounter} color={"secondary"} />,
	},
	{
		label: t("sessionDetails.tab.presentations"),
		to: "presentations",
		iconPosition: "end",
		icon: <Badge showZero badgeContent={presentationCounter} color={"secondary"} />,
	},
	{
		label: t("sessionDetails.tab.sessionInvitation"),
		to: "sessionInvitation",
	},
	{
		label: t("sessionDetails.tab.clashes"),
		to: "clashes",
		iconPosition: "end",
		icon: <Badge showZero badgeContent={clashCounter} color={"error"} />,
	},
	{
		label: t("sessionDetails.tab.anomalies"),
		to: "anomalies",
		iconPosition: "end",
		icon: <Badge showZero badgeContent={anomalyCounter} color={"warning"} />,
	},
];
